<div class="p-grid" style="justify-content: center">
  <div class="p-col-12 p-md-5 p-text-left">
    <a [routerLink]="['/primaires']">Primaires</a>&nbsp;/&nbsp;
    <a [routerLink]="['/liste-etablissements']">Collèges</a>&nbsp;/&nbsp;
    <a [routerLink]="['/liste-etablissements']">Lycées</a>&nbsp;/&nbsp;
    <a [routerLink]="['/liste-etablissements']">Universités</a>&nbsp;/&nbsp;
    <a [routerLink]="['/liste-etablissements']">Enseignement professionnel</a>
  </div>
</div>
<br/>
<div class="carousel">
  <div class="slides">
    <div class="slide">
      <img src="../../../assets/images/gif/vide.png" class="responsive-image">
    </div>
    <div class="slide">
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/9234/lycee" target="_blank">
        <img src="../../../assets/images/gif/GS-LES-ANGELUS-DE-SAINTE-ODILE-TAOUYAH.png" class="responsive-image">
      </a>
    </div>
    <div class="slide">
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/6529/lycee" target="_blank">
        <img src="../../../assets/images/gif/GS-Elhadj-Kassim-Sacko-Nzerekore.png" class="responsive-image">
      </a>
    </div>
    <div class="slide">
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1589/lycee" target="_blank">
        <img src="../../../assets/images/gif/GS-EMMAUS-SIMBAYA-RATOMA-CONAKRY.png" class="responsive-image">
      </a>
    </div>
    <div class="slide">
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1696/lycee" target="_blank">
        <img src="../../../assets/images/gif/GS-HANAS-MATOTO-CONAKRY.png" class="responsive-image">
      </a>
    </div>
    <div class="slide">
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/3384/lycee" target="_blank">
        <img src="../../../assets/images/gif/GS-DONALD-TRUMP-DUBREKA.png" class="responsive-image">
      </a>
    </div>
    <div class="slide">
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2305/lycee" target="_blank">
        <img src="../../../assets/images/gif/CS-ECOLES-BAH-KANE-RATOMA-CONAKRY.png" class="responsive-image">
      </a>
    </div>
    <div class="slide">
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1874/college" target="_blank">
        <img src="../../../assets/images/gif/LES-E-BOUSSOLE-RATOMA-CONAKRY.png" class="responsive-image">
      </a>
    </div>
    <div class="slide">
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1068/college" target="_blank">
        <img src="../../../assets/images/gif/GS-MICHELLE-OBAMA-MATOTO-CONAKRY.png" class="responsive-image">
      </a>
    </div>
  </div>
</div>
<br/>
<span style="text-align: center;">L'annuaire de l'éducation guinéenne est un répertoire des établissements d'enseignement en Guinée. Sa création a été motivée par le désir d'offrir un outil aux personnes vivant hors de Guinée qui souhaitent financer les études de leurs proches de prendre connaissance de l'offre scolaire guinéenne.
  Cet annuaire permet d'apprécier à distance les infrastructures de ces établissements et, surtout, de pouvoir les contacter à tout moment grâce à des coordonnées mises à jour quotidiennement.
  Au-delà de cet aspect, il permet d'évaluer le parc d'établissements scolaires de la République de Guinée.Ce parc comprend des écoles primaires, des écoles secondaires, des universités et des établissements d'enseignement professionnel.
  Les catégories incluent : - Écoles primaires de Guinée - Conakry -Écoles secondaires de Guinée - Conakry -Instituts et universités de Guinée - Conakry</span>
<br/><br/>
<span class="title-h1 p-m-2 search-text-bold">Annuaire, éducation, Guinée</span>
<br/><br/>
<div class="p-grid" style="justify-content: center">
  <div class="p-col-12 p-md-3 p-text-left">
    <div class="rounded-rectangle">
      <a [routerLink]="['/liste-etablissements']">
        <div style="height: 20px"></div>
        <strong>Écoles primaires</strong>
        <div style="height: 20px"></div>
      </a>
    </div>
  </div>
  <div class="p-col-12 p-md-3 p-text-right">
    <div class="rounded-rectangle">
      <br/>
      <a [routerLink]="['/liste-etablissements']">
        <div style="height: 20px"></div>
        <strong>Écoles secondaires</strong>
        <div style="height: 20px"></div>
      </a>
      <br/>
      <br/>
    </div>
  </div>
  <div class="p-col-12 p-md-3 p-text-right">
    <div class="rounded-rectangle">
      <br/>
      <a [routerLink]="['/liste-etablissements']">
        <div style="height: 20px"></div>
        <strong>Enseignement supérieur - Université</strong>
        <div style="height: 20px"></div>
      </a>
      <br/>
      <br/>
    </div>
  </div>
  <div class="p-col-12 p-md-3 p-text-right">
    <div class="rounded-rectangle">
      <br/>
      <a [routerLink]="['/liste-etablissements']">
        <div style="height: 20px"></div>
        <strong>Enseignement professionnel</strong>
        <div style="height: 20px"></div>
      </a>
      <br/>
      <br/>
    </div>
  </div>
</div>
<br/><br/>
<span style="text-align: center;">
Notre plateforme s'engage à orienter ses utilisateurs vers les meilleurs établissements éducatifs. Nous sélectionnons rigoureusement les écoles pour notre liste «Notre sélection pour vous», en nous basant sur des critères stricts. <br/>Ces institutions doivent jouir d'une excellente réputation académique et se classer régulièrement parmi les meilleures dans les classements nationaux et internationaux.
Elles doivent proposer des programmes spécialisés répondant aux intérêts académiques et professionnels des étudiants, avec des possibilités de personnalisation des parcours. Le corps professoral, reconnu pour son expertise et sa disponibilité, ainsi que des infrastructures modernes, incluant laboratoires, bibliothèques bien équipées et installations sportives de pointe, sont également des critères essentiels.
<br/><br/>De plus, ces établissements doivent offrir de nombreuses opportunités de développement professionnel grâce à des partenariats solides avec des entreprises et un réseau actif d'anciens élèves. Ces éléments combinés font de ces écoles des choix idéaux pour poursuivre des études de qualité et préparer une carrière future.
</span>
<br/><br/>
<span class="title-h1 p-m-2 search-text-bold">Notre sélection pour vous </span>
<br/><br/>
<div class="card">
  <p-dataView #dv [value]="sponsors" layout="list">
    <ng-template let-sponsor pTemplate="listItem">
      <div class="p-col-12">
        <br/>
        <div class="sponsor-list-item">
          <div class="p-grid p-m-0">
            <div class="p-col-fixed div-cadre detail-search p-text-center" style="width:200px">
              Classement
            </div>
            <div class="p-col">
              <a routerLink="#" routerLinkActive="active" class="title-text-bold p-text-uppercase">
                {{sponsor.nom}}
                <div class="grid-line-separator"></div>
              </a>
              <span class="p-tag p-m-1"> {{sponsor.statut}}</span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-home"></i> {{sponsor?.adresse?.district}}
               - {{sponsor?.sousPrefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{sponsor?.sousPrefecture?.prefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{sponsor?.sousPrefecture?.prefecture?.regionAdministrative?.nom}}
              </span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-mobile"></i>
              Gsm: {{sponsor?.adresse?.telephone}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
