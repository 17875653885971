<div style="height: 20px"></div>
<p-panel header="{{pHeaderTitle}}">
  <form [formGroup]="localiteForm">
    <div class="card">
      <div class="p-fluid">
        <div class="p-field p-grid" *ngIf="showLocalites">
          <label for="localite" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            <span class="p-invalid">*</span>{{choixLocalite}}
          </label>
          <div class="p-col-12 p-md-4">
            <span class="p-float-label">
            <p-dropdown inputId="localite" [autoDisplayFirst]="true" [options]="selectValues"
                        id="localite" optionLabel="nom" formControlName="selectValue"
                        placeholder="Selectionner une localité"
                        [ngClass]="{ 'is-invalid': submitted && f.selectValue.errors }">
            </p-dropdown>
            </span>
            <div *ngIf="submitted && f.selectValue.errors" class="p-invalid">
              <div *ngIf="f.selectValue.errors.required">
                <small id="nom-select-help" class="p-invalid">Le {{nomLocalite}} obligatoire.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid div-cadre">
      <div class="form-group p-field p-col-12 p-md-6">
        <label for="nom"><span class="p-invalid">*</span>{{nomLocalite}}</label>
        <input id="nom" type="text" formControlName="nom" class="form-control"
               [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" pInputText/>
        <div *ngIf="submitted && f.nom.errors" class="p-invalid">
          <div *ngIf="f.nom.errors.required">
            <small id="nom-help" class="p-invalid">Le {{nomLocalite}} obligatoire.</small>
          </div>
        </div>
      </div>
      <div class="form-group p-field p-col-12 p-md-3">
        <label for="code"><span class="p-invalid">*</span>{{codeLocalite}}</label>
        <input id="code" type="text" formControlName="code" class="form-control"
               [ngClass]="{ 'is-invalid': submitted && f.code.errors }" pInputText/>
        <div *ngIf="submitted && f.code.errors" class="p-invalid">
          <div *ngIf="f.code.errors.required">
            <small id="code-help" class="p-invalid">Le {{codeLocalite}} obligatoire.</small>
          </div>
        </div>
      </div>
      <div class="form-group p-field p-col-12 p-md-3">
        <label for="nbreSousLocalites"><span class="p-invalid">*</span>{{nbreSousLocalite}}</label>
        <input id="nbreSousLocalites" type="number" formControlName="nbreSousLocalites" class="form-control"
               [ngClass]="{ 'is-invalid': submitted && f.nbreSousLocalites.errors }" pInputText/>
        <div *ngIf="submitted && f.nbreSousLocalites.errors" class="p-invalid">
          <div *ngIf="f.nbreSousLocalites.errors.required">
            <small id="nbreSousLocalites-help" class="p-invalid">Le {{nbreSousLocalites}} est obligatoire.</small>
          </div>
        </div>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label for="densite">{{densite}}</label>
        <input id="densite" type="text" pInputText name="densite" formControlName="densite">
      </div>
      <div class="p-field p-col-12 p-md-3">
        <label for="superficie">{{superficie}}</label>
        <input id="superficie" type="text" pInputText name="superficie" formControlName="superficie">
      </div>
      <div class="p-field p-col-12 p-md-3">
        <label for="population">{{population}}</label>
        <input id="population" type="text" pInputText name="population" formControlName="population">
      </div>
    </div>
  </form>
</p-panel>
<div *ngIf="sucessMessage">
  <div style="height: 10px"></div>
  <p-message class="messages" severity="success" text="{{messageSuccess}}"></p-message>
  <br/>
</div>
