<br/>
<div class="p-grid flex-search p-col-12 p-offset-0">
  <div class="p-col-11 p-text-center">
    <p class="search-text-bold p-text-uppercase">Annuaire des {{typeEtablissement}}</p>
    <p class="search-text-italic">{{etablissements?.length}} {{typeEtablissement}} - {{nbreEvaluations}} évalué(s) - {{nbreEvaluations}} évaluations</p>
  </div>

  <div class="p-grid p-fluid p-col-12">
    <div class="p-col-12 p-md-3 p-p-1">
      <input class="p-m-2" type="text" pInputText placeholder="Nom de l'établissement"
             name="searchQuery.nom" [(ngModel)]="searchQuery.nom">
    </div>
    <div class="p-col-12 p-md-4 p-m-2 p-p-1">
      <p-autoComplete  [(ngModel)]="searchQuery.localite" [suggestions]="filteredLocalites"
                      (click)="showHelpDiv()"
                      name="searchQuery.localite" (onBlur)="outSideLocaliteInputClick()"
                      (completeMethod)="filterAutoCompleteResult($event)"
                      [minLength]="1" placeholder="Localité" [size]="400">
      </p-autoComplete>
      <div style="height: 10px"></div>
      <input type="text" class="p-m-0 text-input-width" name="searchQuery.localites" (click)="showHelpDiv()"
             (blur)="outSideLocaliteInputClick()" *ngIf="showHelpLabel"
             pInputText placeholder="Région, Préfecture, Ss-préfecture">
    </div>
    <div class="p-col-12 p-md-4 p-p-1 p-text-right">
      <div class="p-grid p-col-12 p-text-right">
        <div class="p-col-6 h-auto">
          <button *ngIf="critereChecked" class="p-button-outlined p-button-rounded" type="button" pButton pRipple
                  label="de critères"
                  (click)="showCriteres()" icon="pi pi-minus"></button>
          <button *ngIf="!critereChecked" class="p-button-outlined p-button-rounded" type="button" pButton pRipple
                  label="de critères"
                  (click)="showCriteres()" icon="pi pi-plus"></button>
        </div>
        <div class="p-col-6 h-auto">
          <button type="button" pButton pRipple label="Rechercher" class="p-button-rounded"
                  (click)="loadEtablissementFromQuery($event)"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="critereChecked" class="p-grid flex-search p-col-12 p-offset-0">
  <div class="card p-col-10 panel-search-bg p-m-3">
    <div class="p-grid">
      <div class="p-col-fixed title-text-bold" style="width:300px">Etablissement avec photos :</div>
      <div class="p-col">
        <p-selectButton [options]="photosOptions" [(ngModel)]="photoValue" optionLabel="name"></p-selectButton>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-fixed title-text-bold" style="width:300px">Statut:</div>
      <div class="p-col">
        <p-selectButton [options]="statutOptions" [(ngModel)]="values" multiple="multiple"
                        optionLabel="name"></p-selectButton>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-fixed title-text-bold" style="width:300px">Langues enseignées:</div>
      <div class="p-col">
        <p-multiSelect [options]="langues" [(ngModel)]="selectedLangues" class="multiselect-custom"
                       defaultLabel="Choisir une langue" optionLabel="libelle">
          <ng-template let-value pTemplate="selectedItems">
            <div class="langue-item langue-item-value" *ngFor="let option of selectedLangues">
              <div>{{option.libelle}}</div>
            </div>
            <div *ngIf="!selectedLangues || selectedLangues.length === 0" class="langue-placeholder">
              Choisir une langue
            </div>
          </ng-template>
          <ng-template let-langue pTemplate="item">
            <div class="langue-item">
              <div>
                <img class="img-flag" src="../../../assets/images/{{langue.value.code}}.svg">
                {{langue.value.libelle}}
              </div>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
  </div>
</div>
<br/>
