import {Component, OnInit, ViewChild} from '@angular/core';
import {SuperieurService} from "../../services/superieur/superieur.service";
import {SearchQueryComponent} from "../commun/search-query/search-query.component";
import {Superieur} from "../../models/superieur/superieur.model";

@Component({
  selector: 'app-superieur',
  templateUrl: './superieur.component.html',
  styleUrls: ['./superieur.component.css']
})
export class SuperieurComponent implements OnInit {

  sortOrder: number;
  sortField: string;
  superieurs: Superieur[];
  progressBarValue: boolean = true;
  superieursFromQuery: Superieur[];
  @ViewChild
  (SearchQueryComponent, {static: true}) searchQueryComponent;

  constructor(protected superieurService: SuperieurService) {

  }

  ngOnInit(): void {
    this.loadSuperieurs();
    this.searchQueryComponent.typeEtablissement = "superieurs";
  }

  loadSuperieurs() {
    return this.superieurService.getSuperieurs().subscribe((data) => {
      this.superieurs = data;
      this.superieursFromQuery = data;
      this.searchQueryComponent.etablissements = data;
      this.searchQueryComponent.updateSelectedLocaliteValue();
      this.progressBarValue = false;
      this.searchQueryComponent.nbreEvaluations = data.filter(etablissement => etablissement.evaluated === true).length;
    });
  }

  loadSuperieursFromQuery() {
    this.progressBarValue = true;
    this.searchQueryComponent.updateParams();
    return this.superieurService.getSuperieursFromQuery(this.searchQueryComponent.searchQuery).subscribe((data) => {
      this.superieursFromQuery = data;
      this.searchQueryComponent.updateSelectedLocaliteValue();
      this.progressBarValue = false;
    }, error => {
      console.error('There was an error!', error);
      console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }


}
