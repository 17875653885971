export class SearchQuery {
  nom: string;
  status: string[];
  langues: string[];
  localites: string[];
  localite: string;
  photoOption: string;

  constructor(nom?: string, status?: string[], langues?: string[], localites?: string[],
              localite?: string, photoOption?: string) {
    this.nom = nom;
    this.status = status;
    this.langues = langues;
    this.localites = localites;
    this.localite = localite;
  }

}
