import {Component, OnInit, Output} from '@angular/core';
import {Langue} from "../../../models/langue.model";
import {SearchQuery} from "../../../models/searchquery";
import {CommunService} from "../../../services/commun/commun.service";
import {EventEmitter} from '@angular/core';

@Component({
  selector: 'app-search-query',
  templateUrl: './search-query.component.html',
  styleUrls: ['./search-query.component.css']
})
export class SearchQueryComponent implements OnInit {


  values: any[];
  langues: Langue[];
  statutOptions: any[];
  photosOptions: any[];
  selectedLangues: any[];
  etablissements : any[];
  photoValue: OptionPhoto;
  filteredLocalites: any[];
  selectedLocalite: string;
  autoCompleteResults: any[];
  showHelpLabel: boolean = false;
  critereChecked: boolean = false;
  typeEtablissement: string = "primaires";
  searchQuery: SearchQuery = new SearchQuery();
  @Output('enfant') enfant = new EventEmitter();
  nbreEvaluations: number;

  constructor(protected commonService: CommunService) {
    this.statutOptions = [
      {name: 'Public', value: '1'},
      {name: 'Privé', value: '2'},
    ];
    this.photosOptions = [
      {name: 'Non', value: '1'},
      {name: 'Oui', value: '2'},
    ];
  }

  ngOnInit(): void {
    this.loadLangues();
    this.loadLocalitesNames();
  }

  showHelpDiv() {
    this.showHelpLabel = true;
  }

  outSideLocaliteInputClick() {
    this.showHelpLabel = false;
  }

  showCriteres() {
    this.critereChecked = !this.critereChecked;
  }

  loadLocalitesNames() {
    return this.commonService.getLocaliteNames().subscribe((data) => {
      this.autoCompleteResults = data;
    });
  }

  loadLangues() {
    return this.commonService.getLangues().subscribe((data) => {
      this.langues = data;
    });
  }

  filterAutoCompleteResult(event) {
    let filtered: any[] = [];
    let query = event.query;
    if (query != null && query != undefined && query.length > 2) {
      filtered = this.autoCompleteResults.filter(
        name => name.toLowerCase().includes(query.toLowerCase()));
      if (filtered.length == 0) {
        query = query.replace('e', 'é');
        filtered = this.autoCompleteResults.filter(
          name => name.toLowerCase().includes(query.toLowerCase()));
      }
    }

    this.filteredLocalites = filtered;
  }

  updateLocalites() {
    this.searchQuery.localites = [];
    this.searchQuery.localites.push(this.searchQuery.localite)
  }

  updateParams() {
    this.updateStatus();
    this.updateLangues();
    this.updateLocalites();
    this.updatePhotoOptions();
  }

  resetValues() {
    this.values = [];
    this.langues = [];
    this.selectedLangues = [];
  }

  updateStatus() {
    if (this.values != null && this.values != undefined) {
      this.searchQuery.status = [];
      this.values.forEach((item, index) => {
        this.searchQuery.status.push(item.name);
      });
    }
  }

  updatePhotoOptions() {
    if (this.photoValue != null && this.photoValue != undefined) {
      this.searchQuery.photoOption = this.photoValue.name;
    }
  }

  updateLangues() {
    if (this.selectedLangues != null || this.selectedLangues != undefined) {
      this.searchQuery.langues = [];
      this.selectedLangues.forEach((item, index) => {
        this.searchQuery.langues.push(item.code);
      });
    }
  }

  updateSelectedLocaliteValue() {
    if (this.searchQuery.localite != null && this.searchQuery.localite.length > 0) {
      this.selectedLocalite = this.searchQuery.localite;
    } else {
      this.selectedLocalite = "Guinée";
    }
  }

  loadEtablissementFromQuery($event: Event) {
    this.enfant.emit($event)
  }

}

class OptionPhoto {
  name: string;
  value: string;
}
