// src/app/services/flash-info.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlashInfoService {
  private flashMessages = new BehaviorSubject<string[]>([]);
  flashMessages$ = this.flashMessages.asObservable();

  showFlashMessage(message: string, duration: number = 5000): void {
    const currentMessages = this.flashMessages.getValue();
    this.flashMessages.next([...currentMessages, message]);
    setTimeout(() => {
      this.removeFlashMessage(message);
    }, duration);
  }

  private removeFlashMessage(message: string): void {
    const currentMessages = this.flashMessages.getValue();
    this.flashMessages.next(currentMessages.filter(m => m !== message));
  }
}
