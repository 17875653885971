import {Injectable} from '@angular/core';
import {Superieur} from "../../models/superieur/superieur.model";
import {Observable} from "rxjs/index";
import {Lycee} from "../../models/lycee/lycee";
import {HttpClient} from "@angular/common/http";
import {Primaire} from "../../models/primaire/primaire";
import {SearchQuery} from "../../models/searchquery";

@Injectable({
  providedIn: 'root'
})
export class LyceeService {

  constructor(protected http: HttpClient) {
  }

  getLycees(): Observable<Lycee[]> {
    return this.http.get<Lycee[]>('https://api.annuaireeducationguinee.com/letudiant/lycees');
  }

  findLycee(id: number): Observable<Lycee> {
    return this.http.get<Lycee>("https://api.annuaireeducationguinee.com/letudiant/lycee/" + id);
  }

  getLyceesFromQuery(searchQuery: SearchQuery): Observable<Lycee[]> {
    return this.http.post<Lycee[]>('https://api.annuaireeducationguinee.com/letudiant/lycees/criteres/search', searchQuery);
  }

  getLyceesFromSousPrefectureName(searchQuery: SearchQuery): Observable<Lycee[]> {
      return this.http.post<Lycee[]>('https://api.annuaireeducationguinee.com/letudiant/lycees/sous-prefecture/name', searchQuery);
  }

  getClassementParAnnee(): Observable<Lycee[]> {
      return this.http.get<Lycee[]>('https://api.annuaireeducationguinee.com/letudiant/lycees/classement');
  }

}
