import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {PrimaireComponent} from './pages/primaire/primaire.component';
import {PrimairesComponent} from './pages/primaires/primaires.component';
import {CollegeComponent} from './pages/college/college.component';
import {LyceeComponent} from './pages/lycee/lycee.component';
import {SuperieurComponent} from './pages/superieur/superieur.component';
import {ProfessionnelComponent} from "./pages/professionnel/professionnel.component";
import {DetailEtablissementComponent} from "./pages/commun/detail-etablissement/detail-etablissement.component";
import {DetailLocaliteComponent} from "./pages/commun/detail-localite/detail-localite.component";
import {ListeEtablissementsComponent} from "./pages/commun/liste-etablissements/liste-etablissements.component";
import {CreateAndUpdateRegionComponent} from "./pages/commun/create-and-update-region/create-and-update-region.component";
import {AuthGuard} from "./services/authentification/auth-guard";
import {CreateOrUpdatePrefectureComponent} from "./pages/commun/create-or-update-prefecture/create-or-update-prefecture.component";
import {CreateOrUpdateSousPrefectureComponent} from "./pages/commun/create-or-update-sous-prefecture/create-or-update-sous-prefecture.component";
import {ClassementComponent} from './pages/classement/classement.component';

export const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'primaire', component: PrimaireComponent},
  {path: 'college', component: CollegeComponent},
  {path: 'lycee', component: LyceeComponent},
  {path: 'superieur', component: SuperieurComponent},
  {path: 'professionnel', component: ProfessionnelComponent},
  {path: 'primaires', component: PrimairesComponent},
  {
    path: 'region',
    component: CreateAndUpdateRegionComponent,
    canActivate: [AuthGuard],
    data: {roles: ["admin-niveau-1"]}
  },
  {
    path: 'prefecture',
    component: CreateOrUpdatePrefectureComponent,
    canActivate: [AuthGuard],
    data: {roles: ["admin-niveau-1"]}
  },
  {
    path: 'sous-prefecture',
    component: CreateOrUpdateSousPrefectureComponent,
    canActivate: [AuthGuard],
    data: {roles: ["admin-niveau-1"]}
  },
  {path: 'detail-etablissement/:id/:myURL', component: DetailEtablissementComponent},
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'detail-localite/:id', component: DetailLocaliteComponent},
  {path: 'liste-etablissements', component: ListeEtablissementsComponent},
  {path: 'cPrimaire', component: ClassementComponent},
  {path: 'cCollege', component: ClassementComponent},
  {path: 'cLycee', component: ClassementComponent},
  {path: '', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
