import {Component, OnInit, ViewChild} from '@angular/core';
import {College} from "../../models/college/college";
import {CollegeService} from "../../services/college/college.service";
import {SearchQueryComponent} from "../commun/search-query/search-query.component";

@Component({
  selector: 'app-college',
  templateUrl: './college.component.html',
  styleUrls: ['./college.component.css']
})
export class CollegeComponent implements OnInit {

  sortOrder: number;
  sortField: string;
  colleges: College[];
  collegesFromQuery: College[];
  progressBarValue: boolean = true;
  @ViewChild
  (SearchQueryComponent, {static: true}) searchQueryComponent;

  constructor(protected collegeService: CollegeService) {

  }

  ngOnInit(): void {
    this.loadColleges();
    this.searchQueryComponent.typeEtablissement = "collèges";
  }

  loadColleges() {
    return this.collegeService.getColleges().subscribe((data) => {
      this.colleges = data;
      this.collegesFromQuery = data;
      this.searchQueryComponent.etablissements = data;
      this.searchQueryComponent.updateSelectedLocaliteValue();
      this.searchQueryComponent.nbreEvaluations = data.filter(etablissement => etablissement.evaluated === true).length;
      this.progressBarValue = false;
    });
  }

  loadCollegesFromQuery() {
    this.progressBarValue = true;
    this.searchQueryComponent.updateParams();
    return this.collegeService.getCollegesFromQuery(this.searchQueryComponent.searchQuery).subscribe((data) => {
      this.collegesFromQuery = data;
      this.searchQueryComponent.updateSelectedLocaliteValue();
      this.progressBarValue = false;
    }, error => {
      console.error('There was an error!', error);
      console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }


}
