import {Component, OnInit} from '@angular/core';
import {StatisticAccueil} from "../../models/commun/statistic-accueil";
import {RegionService} from "../../services/region/region.service";
import {StatisticPrefecture} from "../../models/commun/statistic-prefecture";
import {HashMapService} from "../../services/commun/hashmap.service";
import {LabelService} from "../../services/commun/label.service";
import {Prefecture} from "../../models/commun/prefecture";
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  progressBarValue: boolean = true;
  statisticRegionValues: StatisticAccueil[];
  regionConakry: StatisticAccueil[];
  mapRegionsNaturelles : HashMapService<string, StatisticPrefecture[]>;
  mapArray: [string, StatisticPrefecture[]][]; // Déclaration de mapArray


  constructor(private regionService: RegionService,
              private router: Router,
              private labelService: LabelService,
              private hashMapService: HashMapService<string, StatisticPrefecture[]>) {
         this.mapRegionsNaturelles = this.hashMapService;
  }

  ngOnInit(): void {
    this.findRegions();
  }

  handleClick(event: Event, pref: any) {
      event.preventDefault();
      // Votre logique ici
      console.log('Lien cliqué pour:', pref);
      // Set le nombre d'etablissement
      this.labelService.setLabel(pref.nbreAllEtablissements);
      // Navigation manuelle après votre logique
      this.router.navigate(['/detail-localite', pref.id]);
    }

  findRegions() {
    this.regionService.getStatisticRegionValues().subscribe((data) => {
      this.statisticRegionValues = data;
      let basseGuinee: StatisticPrefecture[] = [];
      let hauteGuinee: StatisticPrefecture[] = [];
      let moyenneGuinee: StatisticPrefecture[] = [];
      let guineeForestiere: StatisticPrefecture[] = [];
      this.statisticRegionValues.forEach((item, index) => {
              if (item.id ==1 || item.id ==2 || item.id ==3) {
                console.log(" Basse guinée ");
                basseGuinee.push(...item.prefectureList);
              }
              if (item.id ==4 || item.id ==7) {
                 console.log(" Moyenne guinée ");
                 moyenneGuinee.push(...item.prefectureList);
              }
              if (item.id ==5 || item.id ==6) {
                 console.log(" Haute guinée ");
                 hauteGuinee.push(...item.prefectureList);
              }
              if (item.id ==8) {
                 console.log("Guinée forestière");
                 guineeForestiere.push(...item.prefectureList);
              }
      });
      this.mapRegionsNaturelles.put('Basse-Guinée', basseGuinee);
      this.mapRegionsNaturelles.put('Moyenne-Guinée', moyenneGuinee);
      this.mapRegionsNaturelles.put('Haute-Guinée', hauteGuinee);
      this.mapRegionsNaturelles.put('Guinée forestière', guineeForestiere);
      this.progressBarValue = false;
      this.regionConakry = this.statisticRegionValues.filter(conakry => conakry.id == 1);
      // Convertir la carte en un tableau
      this.mapArray = Array.from(this.mapRegionsNaturelles.entries());
    });
  }

}
