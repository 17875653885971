<div class="messagedefilant">
  <div data-text="">
    <span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/335/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S Habidra (Boké-Baralandé) &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1554/superieur" target="_blank">
         <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="elementaires">&nbsp;
         <img src="../../../assets/images/gif/univ-roi-mohamed-6.png" class="responsive-image"> &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2130/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/koffi-annan.png" class="responsive-image">&nbsp;
        &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2289/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/barack-obama.png" class="responsive-image">&nbsp;
        &nbsp;
      </a>
       <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1553/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="elementaires">&nbsp;
         <img src="../../../assets/images/gif/universite-ahmad-dieng.png" class="responsive-image">&nbsp;
         &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/9232/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/sharebrooke-academy.png" class="responsive-image">
        &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/804/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/univ_lasource.png" class="responsive-image">&nbsp;
      </a>
       <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2125/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="elementaires">&nbsp;
         <img src="../../../assets/images/gif/unc.png" class="responsive-image">&nbsp;&nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/9232/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/GS-LES-ANGELUS-DE-SAINTE-ODILE-TAOUYAH.png" class="responsive-image">&nbsp;&nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1695/college" target="_blank">
        <img src="../../../assets/images/C_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/GS-HANAS-MATOTO-CONAKRY.png" class="responsive-image">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/6529/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/GS-Elhadj-Kassim-Sacko-Nzerekore.png" class="responsive-image">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/3384/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/GS-DONALD-TRUMP-DUBREKA.png" class="responsive-image">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2305/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/CS-ECOLES-BAH-KANE-RATOMA-CONAKRY.png" class="responsive-image">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/3318/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S HAMATISS (Hadja Mama Tiguidanké Sy Savané) &nbsp;&nbsp;
      </a>
    </span>
  </div>
</div>

<app-search-query (enfant)="loadLyceesFromQuery()"></app-search-query>
<div class="card" *ngIf="progressBarValue">
  <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" [showValue]="true"></p-progressBar>
</div>
<div class="p-grid p-col-12">
  <div class="p-text-left">
    <br/>
    <span class="title-text-bold">{{lyceesFromQuery?.length}} lycee(s)</span> -
    {{searchQueryComponent.selectedLocalite}}
  </div>
</div>
<p>Liste | Carte</p>
<div class="line-separator"></div>
<br/>
<div class="card">
  <p-dataView #dv [value]="lyceesFromQuery" [paginator]="true" [rows]="2" filterBy="nom"
              [sortField]="sortField" [sortOrder]="sortOrder" layout="list">
    <ng-template pTemplate="header">
      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
          <i class="pi pi-search"></i>
          <input name="search" type="search" pInputText placeholder="Recherche par nom"
                 (input)="dv.filter($event.target.value)">
        </span>
        <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
      </div>
    </ng-template>
    <ng-template let-lycee pTemplate="listItem">
      <div class="p-col-12">
        <br/>
        <div class="lycee-list-item">
          <div class="p-grid p-m-0">
            <div class="p-col-fixed div-cadre detail-search p-text-center" style="width:200px">
              Classement
            </div>
            <div class="p-col">
              <a routerLink="/detail-etablissement/{{lycee.id}}/lycee" routerLinkActive="active"
                 class="title-text-bold p-text-uppercase">
                {{lycee.nom}}
                <div class="grid-line-separator"></div>
              </a>
              <span class="p-tag p-m-1"> {{lycee.statut}}</span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-home"></i> {{lycee?.adresse?.district}}
               - {{lycee?.sousPrefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{lycee?.sousPrefecture?.prefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{lycee?.sousPrefecture?.prefecture?.regionAdministrative?.nom}}
              </span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-mobile"></i>
              Gsm: {{lycee?.adresse?.telephone}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template let-lycee pTemplate="gridItem">
      <div class="p-col-12 p-md-4">
        <div class="lycee-list-item">
          <div class="p-grid p-m-0">
            <div class="p-col-fixed div-cadre detail-search p-text-center" style="width:150px">
              Classement
            </div>
            <div class="p-col">
              <a routerLink="/detail-etablissement/{{lycee.id}}/lycee" routerLinkActive="active"
                 class="title-text-bold p-text-uppercase">
                {{lycee.nom}}
                <div class="grid-line-separator"></div>
              </a>
              <span class="p-tag p-m-1"> {{lycee.statut}}</span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-home"></i> {{lycee?.adresse?.district}}
               - {{lycee?.sousPrefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{lycee?.sousPrefecture?.prefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{lycee?.sousPrefecture?.prefecture?.regionAdministrative?.nom}}
              </span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-mobile"></i>
              Gsm: {{lycee?.adresse?.telephone}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
