<br/>
<br/>
<p-tabView>
  <p-tabPanel header="NOMBRE D'ETABLISSEMENTS PAR COMMUNE ET PREFECTURE">
    <div class="p-grid p-m-0" style="justify-content: center;">
      <div *ngFor="let regions of mapArray" class="p-col-fixed" style="width:300px">
        <p-card subheader="{{ regions[0] }}" >
          <ul>
            <li *ngFor="let pref of regions[1]">
<!--              <a routerLinkActive="true" routerLink="/detail-localite/{{pref.id}}">-->
<!--                {{pref.nomcourt}} ({{pref.nbreAllEtablissements}})-->
<!--              </a>-->
              <a routerLinkActive="true" routerLink="/detail-localite/{{pref.id}}"
                 (click)="handleClick($event, pref)">
                {{pref.nomcourt}} ({{pref.nbreAllEtablissements}})
              </a>
            </li>
          </ul>
        </p-card>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
<br/>
<br/>
