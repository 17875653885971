import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PrimaireService} from "../../../services/primaire/primaire.service";
import {SuperieurService} from "../../../services/superieur/superieur.service";
import {CollegeService} from "../../../services/college/college.service";
import {LyceeService} from "../../../services/lycee/lycee.service";
import {Location} from '@angular/common';
import {Galleria} from "primeng/galleria";
import {Image} from "../../../models/commun/image";
import {ProfessionnelService} from "../../../services/professionnel/professionnel.service";
import { FieldsetModule } from 'primeng/fieldset';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-detail-etablissement',
  templateUrl: './detail-etablissement.component.html',
  styleUrls: ['./detail-etablissement.component.css']
})
export class DetailEtablissementComponent implements OnInit {

  images: any[] = [];
  id: number;
  first: number = 0;
  etablissement: any;
  hasLangues = false;
  totalRecords: number = 3;
  nomGalerie: string = 'nomGalerie';
  previousUrl: string = "/etablissement";
  warningMsg: boolean = false;
  siteWebDisabled: boolean = false;
  galeriePhotosExist: boolean = false;
  phoneNumber: string = "00224622735167";
  htmlTexte: string;
  infosPersonnel: boolean = false;
  fondateur: any;
  directeur: any;

  displayBasic2: boolean;
  displayBasic3: boolean;
  displayCustom: boolean;
  activeIndex: number = 0;

  constructor(private route: ActivatedRoute,
              private location: Location,
              private readonly router: Router,
              private primaireService: PrimaireService,
              private collegeService: CollegeService,
              private lyceeService: LyceeService,
              private superieurService: SuperieurService,
              private professionnelService: ProfessionnelService) {
    let url = this.router.getCurrentNavigation().initialUrl;
    console.log(" URL >>>>>>> ::: " + url);
    this.previousUrl = url.valueOf().toString();
    if (this.previousUrl == '/') {
      this.previousUrl = router.url;
    }
  }

  onPageChange(event) {
    this.first = event.first;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.previousUrl = "/"+ params.myURL;
      this.loadEtablissement();
    });
  }

  loadEtablissement() {
    switch (this.previousUrl) {
      case '/primaire':
        console.log("It is a primaire.");
        this.loadPrimaire();
        break;
      case '/college':
        console.log("It is a college.");
        this.loadCollege();
        break;
      case '/lycee':
        console.log("It is a lycee.");
        this.loadLycee();
        break;
      case '/superieur':
        console.log("It is a superieur.");
        this.loadSuperieur();
        break;
      case '/professionnel':
        console.log("It is a professionnel.");
        this.loadProfessionnel();
        break;
      default:
        console.log("Aucun établissement ne correspond à cet identifiant!");
        break;
    }
  }

  notEmptyLangues() {
    return this.etablissement != null
      && this.etablissement.langues != null
      && this.etablissement.langues != undefined;
  }

  updateEtablissementValues() {
    if (this.etablissement != null) {
    this.htmlTexte = this.etablissement.motDuResponsable;
      this.updateGalerie();
      console.log(" ************* "+this.notEmptyLangues());
      if (this.notEmptyLangues()) {
        this.hasLangues = true;
      }
      this.loadStatutSiteWeb();
      window.scrollTo(0, 0);
      this.phoneNumber = this.formatPhoneNumber(this.etablissement?.adresse?.telephone);
      this.updateReseauxSociaux();
      if (this.etablissement?.personnes?.length > 0) {
          this.infosPersonnel = true;
          this.fondateur = this.etablissement.personnes.find(personne => personne.statut == 'Fondateur');
          this.directeur = this.etablissement.personnes.find(personne => personne.statut == 'Directeur' || personne.statut == 'Recteur');
          console.log('this.directeur ' + JSON.stringify(this.directeur));
      }
    }
  }

  loadPrimaire() {
    return this.primaireService.findPrimaire(this.id).subscribe((data) => {
      this.etablissement = data;
      this.updateEtablissementValues();
    }, error => {
      console.error('There was an error!', error);
      console.error("+++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }

  loadCollege() {
    return this.collegeService.findCollege(this.id).subscribe((data) => {
      this.etablissement = data;
      this.updateEtablissementValues();
    }, error => {
      console.error('There was an error!', error);
      console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }

  loadLycee() {
    return this.lyceeService.findLycee(this.id).subscribe((data) => {
      this.etablissement = data;
      this.updateEtablissementValues();
    }, error => {
      console.error('There was an error!', error);
      console.error("+++++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }

  loadSuperieur() {
    return this.superieurService.findSuperieur(this.id).subscribe((data) => {
      this.etablissement = data;
      this.updateEtablissementValues();
    }, error => {
      console.error('There was an error!', error);
      console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }

  loadProfessionnel() {
    return this.professionnelService.findProfessionnel(this.id).subscribe((data) => {
      this.etablissement = data;
      this.updateEtablissementValues();
    }, error => {
      console.error('There was an error!', error);
      console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }

  updateGalerie() {
    if (this.etablissement.galeries != null
      && this.etablissement.galeries != undefined
      && this.etablissement.galeries.length > 0) {
      this.galeriePhotosExist = true;
      this.nomGalerie = this.etablissement.galeries[0].nomEtablissementGalerie;
      this.totalRecords = this.etablissement.galeries[0].nbrePhotos;
      for (let i = 1; i <= this.totalRecords; i++) {
        let previewImageSrc = "../../../../assets/images/" + this.nomGalerie+i+".jpg";
        let thumbnailImageSrc = "../../../../assets/images/" + this.nomGalerie+i+"s.jpg";
        let image = new Image(previewImageSrc, thumbnailImageSrc, 'toto', 'titi');
        console.log(image);
        this.images.push(image);
      }
    }
  }

  updateReseauxSociaux() {
      if (this.etablissement.reseauxSociaux != null
        && this.etablissement.reseauxSociaux != undefined
        && this.etablissement.reseauxSociaux?.length > 0) {
        for (let i = 0; i <= this.etablissement.reseauxSociaux.length; i++) {
          console.log(" MAKANERA "+this.etablissement?.reseauxSociaux[i]?.nom)
        }
      }
    }

  loadStatutSiteWeb() {
    if (this.etablissement.siteWeb === 'N/A') {
      this.warningMsg = true;
      this.siteWebDisabled = true;
    }
  }

  redirectToWebSite() {
    if (this.etablissement.siteWeb === 'N/A') {
      this.warningMsg = true;
      this.siteWebDisabled = true;
    } else {
      window.open(this.etablissement.siteWeb, "_blank");
    }
  }

  goBackUrl() {
    this.location.back();
  }

  responsiveOptions:any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

  formatPhoneNumber(phoneNumber: string): string {
      // Supprimer les parenthèses et les espaces du numéro de téléphone
      return phoneNumber.replace(/[\(\) ]/g, '');
  }

}
