import {Component, OnInit, ViewChild} from '@angular/core';
import {LocaliteDatasComponent} from "../localite-datas/localite-datas.component";
import {PrefectureService} from "../../../services/prefecture/prefecture.service";
import {Prefecture} from "../../../models/commun/prefecture";
import {RegionAdministrative} from "../../../models/commun/region-administrative";
import {RegionService} from "../../../services/region/region.service";

@Component({
  selector: 'app-create-or-update-prefecture',
  templateUrl: './create-or-update-prefecture.component.html',
  styleUrls: ['./create-or-update-prefecture.component.css']
})
export class CreateOrUpdatePrefectureComponent implements OnInit {

  labelCancelButton: string = "Cancel";
  labelSaveButton: string = "Enregister";
  @ViewChild
  (LocaliteDatasComponent, {static: true}) localiteDatasComponent;

  constructor(private prefectureService: PrefectureService, private regionService : RegionService) {

  }

  ngOnInit(): void {
    this.updateTitles();
    this.findRegions();
  }

  onSubmit() {
    this.localiteDatasComponent.submitted = true;
    if (this.localiteDatasComponent.localiteForm.invalid) {
      return;
    }
    // Le formulaire est valide et l'ajout est possibe
    this.addPrefecture();

  }

  findRegions() {
    this.regionService.getRegions().subscribe((data) => {
      this.localiteDatasComponent.selectValues = data;
    });
  }

  addPrefecture() {
    let prefecture = CreateOrUpdatePrefectureComponent.updateNewPrefectureValues(this.localiteDatasComponent.localiteForm.value);
    this.prefectureService.addNewPrefecture(prefecture).subscribe(value => {
      this.localiteDatasComponent.messageSuccess = 'La préfecture a été enregistrée avec succès';
      this.localiteDatasComponent.sucessMessage = true;
    }, error => {
      console.log('FAIL to localite axe!', error);
      this.localiteDatasComponent.sucessMessage = false;
    }, () => {
      console.log('POST axe - now completed.');
    });
  }

  updateTitles() {
    this.localiteDatasComponent.showLocalites = true;
    this.localiteDatasComponent.nomLocalite = 'Nom de la préfecture';
    this.localiteDatasComponent.choixLocalite = 'Choix de la région';
    this.localiteDatasComponent.codeLocalite = 'Code de la préfecture';
    this.localiteDatasComponent.pHeaderTitle = 'Création de la préfecture';
    this.localiteDatasComponent.nbreSousLocalite = 'Nombre de sous-préfectures';
  }

  static updateNewPrefectureValues(inputPrefecture: any) {
    let prefecture: Prefecture;
    prefecture = inputPrefecture;
    prefecture.regionAdministrative = inputPrefecture.selectValue;
    prefecture.nbreSousPrefectures = inputPrefecture.nbreSousLocalites;
    return prefecture
  }

  onReset() {
    this.localiteDatasComponent.onReset();
    this.localiteDatasComponent.sucessMessage = false;
  }

}
