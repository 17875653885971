<div class="p-grid" style="justify-content: center">
  <div class="p-col-12 p-md-5 p-text-left">
    <a [routerLink]="['/primaires']">Primaires</a>&nbsp;/&nbsp;
    <a [routerLink]="['/liste-etablissements']">Collèges</a>&nbsp;/&nbsp;
    <a [routerLink]="['/liste-etablissements']">Lycées</a>&nbsp;/&nbsp;
    <a [routerLink]="['/liste-etablissements']">Universités</a>&nbsp;/&nbsp;
    <a [routerLink]="['/liste-etablissements']">Enseignement professionnel</a>
  </div>
</div>
<br/>
<br/><br/>
<span
  class="title-h1 p-m-2 p-text-uppercase search-text-bold">Établissements scolaires de la {{this.titrePrefecture}} - ({{totalNbreEtablissements}})</span>
<br/><br/>
<div class="card p-tag p-m-2" style="width:100%;text-align: left">
  <div class="line-separator"></div>
  <span class="search-text-bold" style="text-align: left;font-size: 1.5rem;">
<!--    <i class="pi pi-eye-slash" style="font-size: 1.5rem"></i>-->
    <i class="pi pi-spin pi-cog" style="font-size: 1.2rem"></i>
    STATISTIQUES  </span>
  <div class="line-separator"></div>
</div>
<br/>
<span class="titre-trois p-m-2">Afficher toutes les statistiques</span>
<br/><br/>
<div class="p-grid flex-search p-col-12 p-offset-0 p-m-2">
  Filtrer les résultats
  <br/>
</div>
<div class="line-separator flex-search p-m-2"></div>
<div class="p-grid flex-search p-col-12 p-offset-0 p-m-2">
  <br/>
  <span style="width: 100%;" class="search-text-bold">Statuts des établissements</span>
  <br/><br/>
  <div class="flex align-items-center">
    <p-checkbox [(ngModel)]="publics" [binary]="true" inputId="binary" name="public" value="public"></p-checkbox>
    &nbsp;
    <img src="../../../assets/images/vide_bleu.png" height="24" width="24" title="Publics">
    &nbsp;<label>Publics</label>
  </div>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <div class="flex align-items-center">
    <p-checkbox [(ngModel)]="prives" [binary]="true" inputId="binary" name="prive" value="prive"></p-checkbox>
    &nbsp;
    <img src="../../../assets/images/vide_orange.png" height="24" width="24" title="Prives">
    &nbsp;<label>Privés</label>
  </div>
  <br/><br/>
  <br/><br/>
  <span style="width: 100%;" class="search-text-bold">Types d'établissements</span>
  <br/><br/>
  <div class="flex align-items-center">
    <p-checkbox [(ngModel)]="elementaires" [binary]="true" inputId="binary" name="elementaire"
                value="elementaires"></p-checkbox>
    &nbsp;
    <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">
    &nbsp;<label>Écoles élémentaires</label>
  </div>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <div class="flex align-items-center">
    <p-checkbox [(ngModel)]="colleges" [binary]="true" inputId="binary" name="college" value="college"></p-checkbox>
    &nbsp;
    <img src="../../../assets/images/C_neutre.png" height="24" width="24" title="Colleges">
    &nbsp;<label>Collèges</label>
  </div>
  <br/>
  <span style="width: 100%;" class="search-text-bold"></span>
  <br/>
  <div class="flex align-items-center">
    <p-checkbox [(ngModel)]="lycees" [binary]="true" inputId="binary" name="lycee" value="lycee"></p-checkbox>
    &nbsp;
    <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="Lycees">
    &nbsp;<label>Lycées</label>
  </div>
  <span style="width: 100%;" class="search-text-bold"></span>
  <br/>
  <div class="flex align-items-center">
    <p-checkbox [(ngModel)]="superieurs" [binary]="true" inputId="binary" name="superieur"
                value="superieur"></p-checkbox>
    &nbsp;
    <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="elementaires">
    &nbsp;<label>Universités</label>
  </div>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <div class="flex align-items-center">
    <p-checkbox [(ngModel)]="professionnels" [binary]="true" inputId="binary" name="professionnel"
                value="professionnel"></p-checkbox>
    &nbsp;
    <img src="../../../assets/images/C_neutre.png" height="24" width="24" title="Professionnels">
    &nbsp;<label>Écoles professionnelles</label>
  </div>
  <br/>
  <span style="width: 100%;" class="search-text-bold"></span>
  <br/>
  <div class="p-grid">
    <div class="p-col-fixed title-text-bold" style="width:215px">Veuillez choisir une localité :</div>
    <div class="p-col">
      <div class="card flex justify-content-center">
        <p-dropdown [options]="sousPrefectures" [(ngModel)]="selectedSousPrefecture" optionLabel="nom"
                    [showClear]="true" placeholder="Veuillez choisir une localité">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedSousPrefecture">
<!--              <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"-->
<!--                   [class]="'flag flag-' + selectedSousPrefecture.code.toLowerCase()" style="width: 18px"/>-->
              <div> {{ selectedSousPrefecture.nom }}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
<!--              <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"-->
<!--                   [class]="'flag flag-' + country.value.code.toLowerCase()" style="width: 18px"/>-->
              <div>{{ country.value.nom }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <p-message *ngIf="warningMsg" severity="error" text="Veuillez selectionner une localité"></p-message>
    </div>
  </div>
  <div class="grid-line-separator"></div>
  <span style="width: 100%;" class="search-text-bold"></span>
  <br/>
  <p-button label="Filtrer l'affichage" (click)="filtrer()"></p-button>
  <br/> <br/>
</div>
<div class="search-text-bold div-vide">
  <div class="card p-m-3" *ngIf="progressBarValue">
    <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" [showValue]="true"></p-progressBar>
  </div>
</div>
<div class="p-grid p-col-12">
  <div class="card p-tag p-m-2" style="width:100%;text-align: left">
    <div class="line-separator"></div>
      <span class="search-text-bold" style="text-align: left;font-size: 1.5rem;">
        <i class="pi pi-search" style="font-size: 1.2rem"></i>
        {{totalNbreEtablissements}} établissements trouvés  </span>
    <div class="line-separator"></div>
  </div>
  <span style="width: 100%;" class="search-text-bold"></span>
  <div class="div-vide-dix"><p-message *ngIf="emptyEtablissement" severity="error" text="Cette localité nécessite de l'aide car elle n'a pas d'établissement."></p-message></div>
  <div id="primaires" *ngIf="elementaires">
    <span class="search-text-bold" *ngIf="primairesFromQuery?.length > 0">Primaires</span>
    <div style="height: 10px"></div>
    <div *ngFor="let primaire of primairesFromQuery" style="width:100%">
      <div class="rounded-rectangle">
        <div class="p-m-2">
          <a routerLink="/detail-etablissement/{{primaire.id}}/primaire" routerLinkActive="active">
            <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="Primaires"> &nbsp;
            <span style="width: 100%;" class="title-of-school">{{primaire.nom}}</span>
          </a><br/>
          {{primaire.adresse.district}}&nbsp;{{primaire.adresse.description}}
        </div>
      </div>
      <div style="height: 10px"></div>
    </div>
  </div>
  <div style="width: 10px"></div>
  <div id="colleges" *ngIf="colleges">
<!--    <div class="div-vide-dix"></div>-->
    <span class="search-text-bold" *ngIf="collegesFromQuery?.length > 0">Collèges</span>
    <div style="height: 10px"></div>
    <div *ngFor="let college of collegesFromQuery" style="width:100%">
      <div class="rounded-rectangle">
        <div class="p-m-2">
          <a routerLink="/detail-etablissement/{{college.id}}/college" routerLinkActive="active">
            <img src="../../../assets/images/C_neutre.png" height="24" width="24" title="Collèges"> &nbsp;
            <span style="width: 100%;" class="title-of-school">{{college.nom}}</span>
          </a><br/>
          {{college.adresse.district}}&nbsp;{{college.adresse.description}}
        </div>
      </div>
      <div style="height: 10px"></div>
    </div>
    <span style="width: 100%;" class="search-text-bold"></span>
    <div class="div-vide-dix"></div>
  </div>
  <div style="width: 10px"></div>
  <div id="lycee" *ngIf="lycees">
    <span class="search-text-bold" *ngIf="lyceesFromQuery?.length > 0">Lycées</span>
    <div style="height: 10px"></div>
    <div *ngFor="let lycee of lyceesFromQuery" style="width:100%">
      <div class="rounded-rectangle">
        <div class="p-m-2">
          <a routerLink="/detail-etablissement/{{lycee.id}}/lycee" routerLinkActive="active">
            <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="Lycees"> &nbsp;
            <span style="width: 100%;" class="title-of-school">{{lycee.nom}}</span>
          </a><br/>
          {{lycee.adresse.district}}&nbsp;{{lycee.adresse.description}}
        </div>
      </div>
      <div style="height: 10px"></div>
    </div>
  </div>
  <div style="width: 10px"></div>
  <div id="superieurs" *ngIf="superieurs">
    <span class="search-text-bold" *ngIf="superieursFromQuery?.length > 0">Facultés / Professionnels </span>
    <div style="height: 10px"></div>
    <div *ngFor="let superieur of superieursFromQuery" style="width:100%">
      <div class="rounded-rectangle">
        <div class="p-m-2">
          <a routerLink="/detail-etablissement/{{superieur.id}}/superieur" routerLinkActive="active">
            <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="Superieurs"> &nbsp;
            <span style="width: 100%;" class="title-of-school">{{superieur.nom}}</span>
          </a><br/>
          {{superieur.adresse.district}}&nbsp;{{superieur.adresse.description}}
        </div>
      </div>
      <div style="height: 10px"></div>
    </div>
  </div>
</div>

