import { Injectable } from '@angular/core';
import {Superieur} from "../../models/superieur/superieur.model";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {httpOptions} from "../../pages/app.constant";
import {Prefecture} from "../../models/commun/prefecture";

@Injectable({
  providedIn: 'root'
})
export class PrefectureService {

  constructor(protected http: HttpClient) { }

  getPrefectures(): Observable<Prefecture[]> {
    return this.http.get<Prefecture[]>('https://api.annuaireeducationguinee.com/letudiant/prefectures');
  }

  findPrefecture(id: number): Observable<Prefecture> {
    return this.http.get<Prefecture>("https://api.annuaireeducationguinee.com/letudiant/prefecture/" + id);
  }

  addNewPrefecture(prefecture: Prefecture): Observable<Object> {
    // let localPrefecture = prefecture;
    // localPrefecture.nbreSousPrefectures = prefecture.nbreSousLocalite;
    // console.log(" AHHHHHHH " + localPrefecture.nbreSousPrefectures);
    // console.log(" OHHHHHHH " + JSON.stringify(localPrefecture.nbreSousPrefectures));
    // localPrefecture.regionAdministrative = prefecture.selectValues;
    console.log(" 888888888888888 " + JSON.stringify(prefecture));
    return this.http.post('https://api.annuaireeducationguinee.com/letudiant/prefecture/save', prefecture, httpOptions);
  }

}
