<header>
  <div class="p-grid">
    <div class="p-col-5 p-text-left">
      <a [routerLink]="['/']">
        <img src="../../../assets/images/index.png" class="responsive-image">
      </a>
    </div>
    <div class="p-col-6 p-text-right p-mb-1">
      <a class="p-mb-1">
        <i class="pi pi-fw pi-user"></i>
        Mon Compte
      </a>
<!--      <div class="p-col-6 flash-info"  *ngFor="let message of messages">-->
<!--        {{ message }}-->
<!--      </div>-->
    </div>
  </div>
  <p-menubar [model]="items" routerLinkActive="active">
    <ng-template pTemplate="start">
      <a [routerLink]="['/']">
        <!--<i class="pi pi-home"></i>-->
        <img src="../../../assets/images/accueil.png" height="30" class="p-mr-2">
      </a>
    </ng-template>
    <ng-template pTemplate="end">
      <input name="" type="text" pInputText placeholder="Rechercher">
    </ng-template>
  </p-menubar>
</header>
