import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {College} from "../../models/college/college";
import {SearchQuery} from "../../models/searchquery";
import {httpOptions} from "../../pages/app.constant";
import {Primaire} from "../../models/primaire/primaire";

@Injectable({
  providedIn: 'root'
})
export class CollegeService {

  constructor(protected http: HttpClient) {
  }

  getColleges(): Observable<College[]> {
    return this.http.get<College[]>('https://api.annuaireeducationguinee.com/letudiant/colleges');
  }

  getClassementParAnnee(): Observable<College[]> {
      return this.http.get<College[]>('https://api.annuaireeducationguinee.com/letudiant/colleges/classement');
  }

  findCollege(id: number): Observable<College> {
    return this.http.get<College>("https://api.annuaireeducationguinee.com/letudiant/college/" + id);
  }

  getCollegesFromQuery(searchQuery: SearchQuery): Observable<College[]> {
    return this.http.post<College[]>('https://api.annuaireeducationguinee.com/letudiant/colleges/criteres/search', searchQuery);
  }

   getCollegesFromSousPrefectureName(searchQuery: SearchQuery): Observable<College[]> {
        return this.http.post<College[]>('https://api.annuaireeducationguinee.com/letudiant/colleges/sous-prefecture/name', searchQuery);
   }

}
