import {Injectable} from '@angular/core';
import {SearchQuery} from "../../models/searchquery";
import {Observable} from "rxjs/index";
import {HttpClient} from "@angular/common/http";
import {Primaire} from "../../models/primaire/primaire";

@Injectable({
  providedIn: 'root'
})
export class PrimaireService {

  constructor(protected http: HttpClient) {
  }

  getPrimaires(): Observable<Primaire[]> {
    return this.http.get<Primaire[]>('https://api.annuaireeducationguinee.com/letudiant/primaires');
  }

  findPrimaire(id: number): Observable<Primaire> {
    return this.http.get<Primaire>("https://api.annuaireeducationguinee.com/letudiant/primaire/" + id);
  }

  getPrimairesFromQuery(searchQuery: SearchQuery): Observable<Primaire[]> {
    return this.http.post<Primaire[]>('https://api.annuaireeducationguinee.com/letudiant/primaires/criteres/search', searchQuery);
  }

   getPrimairesFromSousPrefectureName(searchQuery: SearchQuery): Observable<Primaire[]> {
     return this.http.post<Primaire[]>('https://api.annuaireeducationguinee.com/letudiant/primaires/sous-prefecture/name', searchQuery);
   }

   getClassementParAnnee(): Observable<Primaire[]> {
      return this.http.get<Primaire[]>('https://api.annuaireeducationguinee.com/letudiant/primaires/classement');
   }

}


