<div class="messagedefilant">
  <div data-text="">
    <span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/319/primaire" target="_blank">
         <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;École primaire de Gorèye &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/335/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S Habidra (Boké-Baralandé) &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/321/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S K.p.c de Boké &nbsp;
      </a>
       <a href="https://www.annuaireeducationguinee.com/detail-etablissement/422/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S Charlotte ( Bruxelles - Kamsar)&nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/9232/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S Les Angelus de sainte Odile (Taouyah-Impôt - Conakry) &nbsp;&nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/3358/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S Georges Fofana (Dubreka) &nbsp;&nbsp;
      </a>
       <a href="https://www.annuaireeducationguinee.com/detail-etablissement/6501/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S Moderne Kassim Sacko (Nzérékoré-Centre) &nbsp;&nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/3318/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S HAMATISS (Hadja Mama Tiguidanké Sy Savané) &nbsp;&nbsp;
      </a>
    </span>
  </div>
</div>
<app-search-query (enfant)="loadPrimairesFromQuery()"></app-search-query>
<div class="card" *ngIf="progressBarValue">
  <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" [showValue]="true"></p-progressBar>
</div>
<div class="p-grid p-col-12">
  <div class="p-text-left">
    <br/>
    <span class="title-text-bold">{{primairesFromQuery?.length}} primaire(s)</span> -
    {{searchQueryComponent.selectedLocalite}}
  </div>
</div>
<p>Liste | Carte</p>
<div class="line-separator"></div>
<br/>
<div class="card">
  <p-dataView #dv [value]="primairesFromQuery" [paginator]="true" [rows]="2" filterBy="nom"
              [sortField]="sortField" [sortOrder]="sortOrder" layout="list">
    <ng-template pTemplate="header">
      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
          <i class="pi pi-search"></i>
          <input name="search" type="search" pInputText placeholder="Recherche par nom"
                 (input)="dv.filter($event.target.value)">
        </span>
        <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
      </div>
    </ng-template>
    <ng-template let-primaire pTemplate="listItem">
      <div class="p-col-12">
        <br/>
        <div class="primaire-list-item">
          <div class="p-grid p-m-0">
            <div class="p-col-fixed div-cadre detail-search p-text-center" style="width:150px">
              Classement
            </div>
            <div class="p-col">
              <a routerLink="/detail-etablissement/{{primaire.id}}/primaire" routerLinkActive="active"
                 class="title-text-bold p-text-uppercase">
                {{primaire.nom}}
                <div class="grid-line-separator"></div>
              </a>
              <span class="p-tag p-m-1"> {{primaire.statut}}</span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-home"></i> {{primaire?.adresse?.district}}
               - {{primaire?.sousPrefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{primaire?.sousPrefecture?.prefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{primaire?.sousPrefecture?.prefecture?.regionAdministrative?.nom}}
              </span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-mobile"></i>
              Gsm: {{primaire?.adresse?.telephone}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template let-primaire pTemplate="gridItem">
      <div class="p-col-12 p-md-4">
        <div class="primaire-list-item">
          <div class="p-grid p-m-0">
            <div class="p-col-fixed div-cadre detail-search p-text-center" style="width:150px">
              Classement
            </div>
            <div class="p-col">
              <a routerLink="/detail-etablissement/{{primaire.id}}/primaire" routerLinkActive="active"
                 class="title-text-bold p-text-uppercase">
                {{primaire.nom}}
                <div class="grid-line-separator"></div>
              </a>
              <span class="p-tag p-m-1"> {{primaire.statut}}</span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-home"></i> {{primaire?.adresse?.district}}
               - {{primaire?.sousPrefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{primaire?.sousPrefecture?.prefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{primaire?.sousPrefecture?.prefecture?.regionAdministrative?.nom}}
              </span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-mobile"></i>
              Gsm: {{primaire?.adresse?.telephone}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
