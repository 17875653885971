<div class="messagedefilant">
  <div data-text="">
    <span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/335/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S Habidra (Boké-Baralandé) &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/321/primaire" target="_blank">
         <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="ELEMENTAIRE">&nbsp;
         <img src="../../../assets/images/gif/GROUPE-SCOLAIRE-KPC-BOKE-BARALANDE.png"> &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1554/superieur" target="_blank">
         <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
         <img src="../../../assets/images/gif/univ-roi-mohamed-6.png"> &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2130/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
        <img src="../../../assets/images/gif/koffi-annan.png">&nbsp;
        &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2289/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
        <img src="../../../assets/images/gif/barack-obama.png">&nbsp;
        &nbsp;
      </a>
       <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1553/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
         <img src="../../../assets/images/gif/universite-ahmad-dieng.png">&nbsp;
         &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/9232/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/sharebrooke-academy.png">
        &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/804/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
        <img src="../../../assets/images/gif/univ_lasource.png">&nbsp;
      </a>
       <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2125/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
         <img src="../../../assets/images/gif/unc.png">&nbsp;&nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/9232/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/GS-LES-ANGELUS-DE-SAINTE-ODILE-TAOUYAH.png">&nbsp;&nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1695/college" target="_blank">
        <img src="../../../assets/images/C_neutre.png" height="24" width="24" title="COLLEGE">&nbsp;
        <img src="../../../assets/images/gif/GS-HANAS-MATOTO-CONAKRY.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/6529/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="LYCEE">&nbsp;
        <img src="../../../assets/images/gif/GS-Elhadj-Kassim-Sacko-Nzerekore.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/3384/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="LYCEE">&nbsp;
        <img src="../../../assets/images/gif/GS-DONALD-TRUMP-DUBREKA.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/968/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
        <img src="../../../assets/images/gif/UNIVERSITE-AL-EAMAR-MATOTO-CONAKRY.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2305/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="LYCEE">&nbsp;
        <img src="../../../assets/images/gif/CS-ECOLES-BAH-KANE-RATOMA-CONAKRY.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/286/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="LYCEE">&nbsp;
        <img src="../../../assets/images/gif/G.S-DESTIN-HADJA-DJENABA-BAH-DUBREKA.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/3318/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S HAMATISS (Hadja Mama Tiguidanké Sy Savané) &nbsp;&nbsp;
      </a>
    </span>
  </div>
</div>
<app-search-query (enfant)="loadSuperieursFromQuery()"></app-search-query>
<div class="card" *ngIf="progressBarValue">
  <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" [showValue]="true"></p-progressBar>
</div>
<div class="p-grid p-col-12">
  <div class="p-text-left">
    <br/>
    <span class="title-text-bold">{{superieursFromQuery?.length}} superieur(s)</span> -
    {{searchQueryComponent.selectedLocalite}}
  </div>
</div>
<p>Liste | Carte</p>
<div class="line-separator"></div>
<br/>
<div class="card">
  <p-dataView #dv [value]="superieursFromQuery" [paginator]="true" [rows]="2" filterBy="nom"
              [sortField]="sortField" [sortOrder]="sortOrder" layout="list">
    <ng-template pTemplate="header">
      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
          <i class="pi pi-search"></i>
          <input name="search" type="search" pInputText placeholder="Recherche par nom"
                 (input)="dv.filter($event.target.value)">
        </span>
        <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
      </div>
    </ng-template>
    <ng-template let-superieur pTemplate="listItem">
      <div class="p-col-12">
        <br/>
        <div class="superieur-list-item">
          <div class="p-grid p-m-0">
            <div class="p-col-fixed div-cadre detail-search p-text-center" style="width:200px">
              Classement
            </div>
            <div class="p-col">
              <a routerLink="/detail-etablissement/{{superieur.id}}/superieur" routerLinkActive="active"
                 class="title-text-bold p-text-uppercase">
                {{superieur.nom}}
                <div class="grid-line-separator"></div>
              </a>
              <span class="p-tag p-m-1"> {{superieur.statut}}</span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-home"></i> {{superieur?.adresse?.district}}
               - {{superieur?.sousPrefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{superieur?.sousPrefecture?.prefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{superieur?.sousPrefecture?.prefecture?.regionAdministrative?.nom}}
              </span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-mobile"></i>
              Gsm: {{superieur?.adresse?.telephone}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template let-superieur pTemplate="gridItem">
      <div class="p-col-12 p-md-4">
        <div class="superieur-grid-item card">
          <div class="superieur-grid-item-top">
            <div>
              <span class="superieur-category">{{superieur.nom}}</span>
            </div>
            <span [class]="'superieur-badge status-' + superieur.localite?.ville.toLowerCase()">{{superieur.localite?.ville}}</span>
          </div>
          <div class="superieur-grid-item-content">
            <img src="../../../assets/images/primeng.svg" [alt]="superieur.nom">
            <div class="superieur-name">{{superieur.name}}</div>
            <div class="superieur-description">{{superieur.sigle}}</div>
            <p-rating [ngModel]="superieur.rating" [readonly]="true" [cancel]="false"></p-rating>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
