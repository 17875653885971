import { Injectable } from '@angular/core';
import {Superieur} from "../../models/superieur/superieur.model";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {RegionAdministrative} from "../../models/commun/region-administrative";
import {httpOptions} from "../../pages/app.constant";
import {StatisticAccueil} from "../../models/commun/statistic-accueil";

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(protected http: HttpClient) { }

  getRegions(): Observable<RegionAdministrative[]> {
    return this.http.get<RegionAdministrative[]>('https://api.annuaireeducationguinee.com/letudiant/regions');
  }

  findRegion(id: number): Observable<Superieur> {
    return this.http.get<Superieur>("https://api.annuaireeducationguinee.com/letudiant/superieur/" + id);
  }

  addNewRegion(region: RegionAdministrative): Observable<Object> {
    console.log(" 888888888888888 " + JSON.stringify(region));
    return this.http.post('https://api.annuaireeducationguinee.com/letudiant/region/save', region, httpOptions);
  }

  getStatisticRegionValues(): Observable<StatisticAccueil[]> {
    return this.http.get<StatisticAccueil[]>('https://api.annuaireeducationguinee.com/letudiant/statistic/all');
  }

}
