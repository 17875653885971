import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-localite-datas',
  templateUrl: './localite-datas.component.html',
  styleUrls: ['./localite-datas.component.css']
})
export class LocaliteDatasComponent implements OnInit {

  submitted = false;
  sucessMessage = false;
  showLocalites = false;
  selectValues: any[];
  localiteForm: FormGroup;
  densite: string = 'Densité';
  superficie: string = 'Superficie';
  population: string = 'Population';
  nomLocalite: string = 'Nom de la région';
  codeLocalite: string = 'Code de la région';
  pHeaderTitle: string = 'Création de la région';
  choixLocalite: string = 'Choisir une localité';
  nbreSousLocalite: string = 'Nombre de préfectures';
  messageSuccess: string = 'La région a été enregistrée avec succès';

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initFormRegion();
  }

  initFormRegion() {
    this.localiteForm = this.fb.group({
      nom: ['', [Validators.required]],
      code: ['', Validators.required],
      densite: [''],
      population: [''],
      superficie: [''],
      selectValue: ['', [Validators.required]],
      nbreSousLocalites: ['', [Validators.required, Validators.min(1), Validators.max(100)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.localiteForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.localiteForm.reset();
  }

}
