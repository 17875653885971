import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {httpOptions} from "../../pages/app.constant";
import {Langue} from "../../models/langue.model";

@Injectable({
  providedIn: 'root'
})
export class CommunService {

  constructor(protected http: HttpClient) {
  }

  getLangues(): Observable<Langue[]> {
    return this.http.get<Langue[]>('https://api.annuaireeducationguinee.com/letudiant/langue/all');
  }

  getLocaliteNames(): Observable<string[]> {
    return this.http.get<string[]>('https://api.annuaireeducationguinee.com/letudiant/region/localites/names');
  }

}
