<br/>
<div style="text-align: center;">
  <span class="title-h1 p-m-2 search-text-bold">{{title}}</span><br/><br/>
  Le classement des meilleurs {{nomEcoles}} de Guinée vous permet de <strong>sélectionner votre établissment ou votre
  ville</strong> et de découvrir les meilleurs {{nomEcoles}} proches de chez vous.
  Au-delà d'un palmarès, <strong>le classement des {{nomEcoles}} 2023-2024 de notre site</strong> est en réalité un
  <strong>état des lieux de la performance des établissements</strong>.
  Il a pour vocation de rendre compte <strong>des qualités des {{nomEcoles}} de votre ville</strong>.
  Plusieurs critères ont été mis en avant par notre site pour déterminer la qualité des établissements.
  Est-ce que tel {{nomEcole}} offre un fort taux de réussite aux examens ? Est-ce que les élèves y ont de bons
  résultats ? Est-ce que le {{nomEcole}} est en capacité de faire progresser ses élèves ? Est-ce que le {{nomEcole}}
  favorise la mixité sociale ?
  <br/><br/>
  <strong>Les {{listSize}} {{nomEcoles}} pris en compte sont répartis en cinq groupes de performance, allant de 1/5 à
    5/5</strong>.
  Vous pouvez les filtrer géographiquement (par ville ou par préfecture), ou encore selon leur statut public ou privé.
</div>
<br/><br/>
<div class="test-rectangle p-md-6">
  <br/><br/>
  <img src="../../../assets/images/E_neutre.png" height="24" width="24">
  <strong>&nbsp;&nbsp;Vous recherchez ou visez...</strong>
  <br/>
  <div class="line-separator p-m-1"></div>
  <div class="line-separator p-m-1"></div>
  <input class="p-md-11 p-m-1 full-width-95" type="text" pInputText placeholder="Que recherchez-vous ?" (click)="showDialog('left')">
  <p-inputIcon styleClass="pi pi-search"></p-inputIcon>
  <div class="card flex flex-column align-items-center gap-2">
    <div class="flex flex-wrap gap-2">
    </div>
    <p-dialog
      header="Recherchez"
      [modal]="true"
      [(visible)]="visible"
      [position]="position"
      [style]="{ width: '25rem' }">
<!--            <span class="p-text-secondary block mb-5"><strong>Crières de recherche...</strong><br/></span>-->
<!--      <br/>-->
      <div class="flex align-items-center gap-3 mb-3">
        <label for="username" class="font-semibold w-6rem"></label>
        <input pInputText id="username" class="flex-auto p-md-11 p-m-1 p-inputtext p-component full-width" autocomplete="off"
               placeholder="Ecrivez le nom des {{nomEcoles}} ?" [(ngModel)]="rechercheValue"/>
      </div>

      <br/>
<!--      <div class="flex align-items-center gap-3 mb-5">-->
<!--        <label for="email" class="font-semibold w-6rem">-->
<!--          Email-->
<!--        </label>-->
<!--        <input pInputText id="email" class="flex-auto" autocomplete="off"/>-->
<!--      </div>-->
      <div class="flex justify-content-end gap-2 p-m-2">
        <p-button label="Rechercher" (click)="pInputValue(); visible = false" (onClick)="visible = false" class="p-button-lg"></p-button>
      </div>
      <div class="flex justify-content-end gap-2 p-m-2">
        <p-button label="Annuler" severity="secondary" (onClick)="visible = false" class="full-width"  style="width: 100%"></p-button>
      </div>
    </p-dialog>
  </div>
</div>
<br/><br/><br/><br/>
<div *ngIf="divPrimaire" class="card p-md-6">
  <p-table [value]="etablissements"
           [paginator]="true"
           [rows]="5"
           styleClass="p-datatable-gridlines custom-datatable">
    <ng-template pTemplate="header">
      <tr>
        <th>Nom établissement</th>
        <th style="width: 70px;">Note</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-etablissement>
      <tr>
        <td>
          <!--          <a routerLink="/detail-etablissement/{{etablissement.id}}/college" routerLinkActive="active">-->
          <!--            <span style="width: 100%;" class="title-of-school">{{etablissement.nom}}</span>-->
          <!--          </a>-->
          <strong><span style="width: 100%;" class="title-of-school">{{ formatName(etablissement.nom) }}</span></strong>
          <br/>
          <ul style="background-color: #F5F5F5;">
            <li>Public/privé : {{ formatStatut(etablissement?.statut)}}</li>
            <li>Taux de réussite en 2024 : {{ etablissement?.statistiqueAnnuelles[0]?.pourcentageReussite
              }}%
            </li>
            <br/>
            <a href="https://www.annuaireeducationguinee.com/detail-etablissement/{{etablissement.id}}/primaire"
               target="_blank">
              <div class="normal-rectangle">
                <br/>
                <span style="color:white">Voir la fiche complète</span>
                <br/>
                <br/>
              </div>
            </a>
            <br/>
          </ul>
        </td>
        <td class="note">{{etablissement?.statistiqueAnnuelles[0]?.note}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div *ngIf="divCollege" class="card p-md-6">
  <p-table [value]="etablissements"
           [paginator]="true"
           [rows]="5"
           styleClass="p-datatable-gridlines custom-datatable">
    <ng-template pTemplate="header">
      <tr>
        <th>Nom établissement</th>
        <th style="width: 70px;">Note</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-etablissement>
      <tr>
        <td>
          <!--          <a routerLink="/detail-etablissement/{{etablissement.id}}/college" routerLinkActive="active">-->
          <!--            <span style="width: 100%;" class="title-of-school">{{etablissement.nom}}</span>-->
          <!--          </a>-->
          <span style="width: 100%;" class="title-of-school">{{ formatName(etablissement.nom) }}</span>
          <br/>
          <ul style="background-color: #F5F5F5;">
            <li>Public/privé : {{ formatStatut(etablissement?.statut)}}</li>
            <li>Taux de réussite en 2024 : {{ etablissement?.statistiqueAnnuelles[0]?.pourcentageReussite
              }}%
            </li>
            <br/>
            <a href="https://www.annuaireeducationguinee.com/detail-etablissement/{{etablissement.id}}/college"
               target="_blank">
              <div class="normal-rectangle">
                <br/>
                <span style="color:white">Voir la fiche complète</span>
                <br/>
                <br/>
              </div>
            </a>
            <br/>
          </ul>
        </td>
        <td class="note">{{etablissement?.statistiqueAnnuelles[0]?.note}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div *ngIf="divLycee" class="card p-md-6">
  <p-table [value]="etablissements"
           [paginator]="true"
           [rows]="5"
           styleClass="p-datatable-gridlines custom-datatable">
    <ng-template pTemplate="header">
      <tr>
        <th>Nom établissement</th>
        <th style="width: 70px;">Note</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-etablissement>
      <tr>
        <td>
          <a routerLink="/detail-etablissement/{{etablissement.id}}/lycee" routerLinkActive="active">
            <span style="width: 100%;" class="title-of-school">{{ formatName(etablissement.nom) }}</span>
          </a><br/>
          <ul style="background-color: #F5F5F5;">
            <li>Public/privé : {{ formatStatut(etablissement?.statut)}}</li>
            <li>Taux de réussite en 2024 : {{ etablissement?.statistiqueAnnuelles[0]?.pourcentageReussite
              }}%
            </li>
            <br/>
            <a href="https://www.annuaireeducationguinee.com/detail-etablissement/{{etablissement.id}}/lycee"
               target="_blank">
              <div class="normal-rectangle">
                <br/>
                <span style="color:white">Voir la fiche complète</span>
                <br/>
                <br/>
              </div>
            </a>
            <br/>
          </ul>
        </td>
        <td class="note">{{etablissement?.statistiqueAnnuelles[0]?.note}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<br/>
