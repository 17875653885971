import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HashMapService<K, V> {
  private map: Map<K, V>;

  constructor() {
    this.map = new Map<K, V>();
  }

  put(key: K, value: V): void {
    this.map.set(key, value);
  }

  get(key: K): V | undefined {
    return this.map.get(key);
  }

  remove(key: K): void {
    this.map.delete(key);
  }

  clear(): void {
    this.map.clear();
  }

  keys(): IterableIterator<K> {
    return this.map.keys();
  }

  values(): IterableIterator<V> {
    return this.map.values();
  }

  size(): number {
    return this.map.size;
  }

  isEmpty(): boolean {
    return this.map.size === 0;
  }

  has(key: K): boolean {
    return this.map.has(key);
  }

  entries(): IterableIterator<[K, V]> {
      return this.map.entries();
    }
}
