import {Component, OnInit, ViewChild} from '@angular/core';
import {LocaliteDatasComponent} from "../localite-datas/localite-datas.component";
import {RegionService} from "../../../services/region/region.service";
import {PrefectureService} from "../../../services/prefecture/prefecture.service";
import {SousPrefectureService} from "../../../services/sous-prefecture/sous-prefecture-service";
import {Prefecture} from "../../../models/commun/prefecture";
import {SousPrefecture} from "../../../models/commun/sous-prefecture";

@Component({
  selector: 'app-create-or-update-sous-prefecture',
  templateUrl: './create-or-update-sous-prefecture.component.html',
  styleUrls: ['./create-or-update-sous-prefecture.component.css']
})
export class CreateOrUpdateSousPrefectureComponent implements OnInit {

  labelCancelButton: string = "Cancel";
  labelSaveButton: string = "Enregister";
  @ViewChild
  (LocaliteDatasComponent, {static: true}) localiteDatasComponent;

  constructor(private regionService: RegionService,
              private prefectureService: PrefectureService,
              private sousPrefectureService: SousPrefectureService) {

  }

  ngOnInit(): void {
    this.updateTitles();
    this.findPrefectures();
  }

  onSubmit() {
    this.localiteDatasComponent.submitted = true;
    if (this.localiteDatasComponent.localiteForm.invalid) {
      return;
    }
    // Le formulaire est valide et l'ajout est possibe
    this.addSousPrefecture();

  }

  findPrefectures() {
    this.prefectureService.getPrefectures().subscribe((data) => {
      this.localiteDatasComponent.selectValues = data;
    });
  }

  addSousPrefecture() {
    CreateOrUpdateSousPrefectureComponent.updateNewSousPrefectureValues(this.localiteDatasComponent.localiteForm.value);
    this.sousPrefectureService.addNewSousPrefecture(this.localiteDatasComponent.localiteForm.value).subscribe(value => {
      console.log('[POST] create localite successfully', value);
    }, error => {
      console.log('FAIL to localite axe!', error);
    }, () => {
      console.log('POST axe - now completed.');
    });
  }

  updateTitles() {
    this.localiteDatasComponent.showLocalites = true;
    this.localiteDatasComponent.choixLocalite = 'Choix de la région';
    this.localiteDatasComponent.nbreSousLocalite = 'Nombre de quartiers';
    this.localiteDatasComponent.nomLocalite = 'Nom de la Sous-préfecture';
    this.localiteDatasComponent.codeLocalite = 'Code de la Sous-préfecture';
    this.localiteDatasComponent.pHeaderTitle = 'Création de la Sous-préfecture';
  }

  onReset() {
    this.localiteDatasComponent.onReset();
  }

  static updateNewSousPrefectureValues(inputSousPrefecture: any) {
    let sousPrefecture: SousPrefecture;
    sousPrefecture = inputSousPrefecture;
    sousPrefecture.prefecture = inputSousPrefecture.selectValue;
    // sousPrefecture. = inputSousPrefecture.nbreSousLocalites;
    return sousPrefecture
  }

}
