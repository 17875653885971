<div class="p-col-8" style="width: 99%; margin: 0 auto; text-align: left;">
  <div class="ticker-wrap">
    <div class="ticker">
      <div class="ticker__item">G.S K.p.c de Boké</div>
      <div class="ticker__item">Université française de Guinée (Ratoma-Kipé)</div>
      <div class="ticker__item">U.N.C (Université Nongo Conakry)</div>
      <div class="ticker__item">G.S Charlotte ( Bruxelles - Kamsar) </div>
      <div class="ticker__item">G.S Ibrahima Baba Kake (Dixinn centre 1) </div>
      <div class="ticker__item">G.S Les Angelus de sainte Odile (Taouyah-Impôt - Conakry)</div>
      <div class="ticker__item">G.S Moderne Kassim Sacko (Nzérékoré-Centre)</div>
      <div class="ticker__item">G.S HAMATISS (Hadja Mama Tiguidanké Sy Savané)</div>
    </div>
  </div>
</div>

<app-search-query (enfant)="loadProfessionnelsFromQuery()"></app-search-query>
<div class="card" *ngIf="progressBarValue">
  <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" [showValue]="true"></p-progressBar>
</div>
<div class="p-grid p-col-12">
  <div class="p-text-left">
    <br/>
    <span class="title-text-bold">{{professionnelsFromQuery?.length}} professionnel(s)</span> -
    {{searchQueryComponent.selectedLocalite}}
  </div>
</div>
<p>Liste | Carte</p>
<div class="line-separator"></div>
<br/>
<div class="card">
  <p-dataView #dv [value]="professionnelsFromQuery" [paginator]="true" [rows]="2" filterBy="nom"
              [sortField]="sortField" [sortOrder]="sortOrder" layout="list">
    <ng-template pTemplate="header">
      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
          <i class="pi pi-search"></i>
          <input name="search" type="search" pInputText placeholder="Recherche par nom"
                 (input)="dv.filter($event.target.value)">
        </span>
        <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
      </div>
    </ng-template>
    <ng-template let-professionnel pTemplate="listItem">
      <div class="p-col-12">
        <br/>
        <div class="professionnel-list-item">
          <div class="p-grid p-m-0">
            <div class="p-col-fixed div-cadre detail-search p-text-center" style="width:200px">
              Classement
            </div>
            <div class="p-col">
              <a routerLink="/detail-etablissement/{{professionnel.id}}/professionnel" routerLinkActive="active"
                 class="title-text-bold p-text-uppercase">
                {{professionnel.nom}}
                <div class="grid-line-separator"></div>
              </a>
              <span class="p-tag p-m-1"> {{professionnel.statut}}</span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-home"></i> {{professionnel?.adresse?.district}}
               - {{professionnel?.sousPrefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{professionnel?.sousPrefecture?.prefecture?.nom}}
              </span>
              <br/>
              <span class="p-m-4">
                {{professionnel?.sousPrefecture?.prefecture?.regionAdministrative?.nom}}
              </span>
              <br/>
              <span class="p-m-1">
              <i class="pi pi-mobile"></i>
              Gsm: {{professionnel?.adresse?.telephone}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template let-professionnel pTemplate="gridItem">
      <div class="p-col-12 p-md-4">
        <div class="professionnel-grid-item card">
          <div class="professionnel-grid-item-top">
            <div>
              <span class="professionnel-category">{{professionnel.nom}}</span>
            </div>
            <span [class]="'professionnel-badge status-' + professionnel.localite?.ville.toLowerCase()">{{professionnel.localite?.ville}}</span>
          </div>
          <div class="professionnel-grid-item-content">
            <img src="../../../assets/images/primeng.svg" [alt]="professionnel.nom">
            <div class="professionnel-name">{{professionnel.name}}</div>
            <div class="professionnel-description">{{professionnel.sigle}}</div>
            <p-rating [ngModel]="professionnel.rating" [readonly]="true" [cancel]="false"></p-rating>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
