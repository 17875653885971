import {Component, OnInit} from '@angular/core';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {PrimaireService} from "../../../services/primaire/primaire.service";
import {SuperieurService} from "../../../services/superieur/superieur.service";
import {CollegeService} from "../../../services/college/college.service";
import {LyceeService} from "../../../services/lycee/lycee.service";
import {Location} from '@angular/common';
import {Galleria} from "primeng/galleria";
import {Image} from "../../../models/commun/image";
import {ProfessionnelService} from "../../../services/professionnel/professionnel.service";
import {PrefectureService} from "../../../services/prefecture/prefecture.service";
import {SousPrefectureService} from "../../../services/sous-prefecture/sous-prefecture-service";
import {Prefecture} from "../../../models/commun/prefecture";
import {SousPrefecture} from "../../../models/commun/sous-prefecture";
import { CheckboxModule } from 'primeng/checkbox';
import {SearchQueryComponent} from "../search-query/search-query.component";
import {CommunService} from "../../../services/commun/commun.service";
import {LabelService} from "../../../services/commun/label.service";
import {Lycee} from "../../../models/lycee/lycee";
import {College} from "../../../models/college/college";
import {Primaire} from "../../../models/primaire/primaire";
import {Superieur} from "../../../models/superieur/superieur.model";
import {Sponsor} from "../../../models/sponsor.model";
import { filter } from 'rxjs/operators';
import { DataViewModule } from 'primeng/dataview';


@Component({
  selector: 'app-liste-etablissements',
  templateUrl: './liste-etablissements.component.html',
  styleUrls: ['./liste-etablissements.component.css']
})
export class ListeEtablissementsComponent implements OnInit {

  sortOrder: number;
  sortField: string;
  sponsors!: Sponsor[];

  constructor(private route: ActivatedRoute, protected superieurService: SuperieurService) {
  }

  ngOnInit(): void {
      this.ourSponsors();
  }

  ourSponsors() {
      this.superieurService.sponsors().subscribe((data) => {
      this.sponsors = data;
      console.log('this.sponsors :'+ this.sponsors.length);
      console.log(JSON.stringify(this.sponsors));
      });
  }

}
