import {Component, OnInit, ViewChild} from '@angular/core';
import {Primaire} from "../../models/primaire/primaire";
import {PrimaireService} from "../../services/primaire/primaire.service";
import {SearchQueryComponent} from "../commun/search-query/search-query.component";

@Component({
  selector: 'app-primaire',
  templateUrl: './primaire.component.html',
  styleUrls: ['./primaire.component.css']
})
export class PrimaireComponent implements OnInit {

  sortOrder: number;
  sortField: string;
  primaires: Primaire[];
  primairesFromQuery: Primaire[];
  progressBarValue: boolean = true;
  @ViewChild
  (SearchQueryComponent, {static: true}) searchQueryComponent;

  constructor(protected primaireService: PrimaireService) {

  }

  ngOnInit(): void {
    this.loadPrimaires();
  }

  loadPrimaires() {
    return this.primaireService.getPrimaires().subscribe((data) => {
      this.primaires = data;
      this.primairesFromQuery = data;
      this.searchQueryComponent.etablissements = data;
      this.searchQueryComponent.updateSelectedLocaliteValue();
      this.searchQueryComponent.nbreEvaluations = data.filter(etablissement => etablissement.evaluated === true).length;
      this.progressBarValue = false;
    });
  }

  loadPrimairesFromQuery() {
    this.progressBarValue = true;
    this.searchQueryComponent.updateParams();
    return this.primaireService.getPrimairesFromQuery(this.searchQueryComponent.searchQuery).subscribe((data) => {
      this.primairesFromQuery = data;
      this.searchQueryComponent.updateSelectedLocaliteValue();
      this.progressBarValue = false;
    }, error => {
      console.error('There was an error!', error);
      console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }


}
