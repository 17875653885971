<div class="messagedefilant">
  <div data-text="">
    <span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/335/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S Habidra (Boké-Baralandé) &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/321/primaire" target="_blank">
         <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="ELEMENTAIRE">&nbsp;
         <img src="../../../assets/images/gif/GROUPE-SCOLAIRE-KPC-BOKE-BARALANDE.png"> &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1554/superieur" target="_blank">
         <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
         <img src="../../../assets/images/gif/univ-roi-mohamed-6.png"> &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2130/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
        <img src="../../../assets/images/gif/koffi-annan.png">&nbsp;
        &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2289/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
        <img src="../../../assets/images/gif/barack-obama.png">&nbsp;
        &nbsp;
      </a>
       <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1553/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
         <img src="../../../assets/images/gif/universite-ahmad-dieng.png">&nbsp;
         &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/9232/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/sharebrooke-academy.png">
        &nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/804/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
        <img src="../../../assets/images/gif/univ_lasource.png">&nbsp;
      </a>
       <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2125/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
         <img src="../../../assets/images/gif/unc.png">&nbsp;&nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/9232/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;
        <img src="../../../assets/images/gif/GS-LES-ANGELUS-DE-SAINTE-ODILE-TAOUYAH.png">&nbsp;&nbsp;
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1695/college" target="_blank">
        <img src="../../../assets/images/C_neutre.png" height="24" width="24" title="COLLEGE">&nbsp;
        <img src="../../../assets/images/gif/GS-HANAS-MATOTO-CONAKRY.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/6529/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="LYCEE">&nbsp;
        <img src="../../../assets/images/gif/GS-Elhadj-Kassim-Sacko-Nzerekore.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/3384/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="LYCEE">&nbsp;
        <img src="../../../assets/images/gif/GS-DONALD-TRUMP-DUBREKA.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/968/superieur" target="_blank">
        <img src="../../../assets/images/U_neutre.png" height="24" width="24" title="UNIVERSITE">&nbsp;
        <img src="../../../assets/images/gif/UNIVERSITE-AL-EAMAR-MATOTO-CONAKRY.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2305/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="LYCEE">&nbsp;
        <img src="../../../assets/images/gif/CS-ECOLES-BAH-KANE-RATOMA-CONAKRY.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/286/lycee" target="_blank">
        <img src="../../../assets/images/L_neutre.png" height="24" width="24" title="LYCEE">&nbsp;
        <img src="../../../assets/images/gif/G.S-DESTIN-HADJA-DJENABA-BAH-DUBREKA.png">
      </a>
      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/3318/primaire" target="_blank">
        <img src="../../../assets/images/E_neutre.png" height="24" width="24" title="elementaires">&nbsp;G.S HAMATISS (Hadja Mama Tiguidanké Sy Savané) &nbsp;&nbsp;
      </a>
    </span>
  </div>
</div>
<div style="height: 10px" xmlns="http://www.w3.org/1999/html"></div>
<div class="card" *ngIf="progressBarValue">
  <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" [showValue]="true"></p-progressBar>
</div>
<!--<span class="title-h1 p-m-4 p-text-uppercase search-text-bold">Guinée</span>-->
<!--<div class="carousel">-->
<!--  <div class="slides">-->
<!--    <div class="slide">-->
<!--      <img src="../../../assets/images/gif/vide.png" class="responsive-image">-->
<!--    </div>-->
<!--    <div class="slide">-->
<!--      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1554/superieur" target="_blank">-->
<!--        <img src="../../../assets/images/gif/univ-roi-mohamed-6.png" class="responsive-image">-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="slide">-->
<!--      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2130/superieur" target="_blank">-->
<!--        <img src="../../../assets/images/gif/koffi-annan.png" class="responsive-image">-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="slide">-->
<!--      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2289/superieur" target="_blank">-->
<!--        <img src="../../../assets/images/gif/barack-obama.png" class="responsive-image">-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="slide">-->
<!--      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/1553/superieur" target="_blank">-->
<!--        <img src="../../../assets/images/gif/universite-ahmad-dieng.png" class="responsive-image">-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="slide">-->
<!--      <a href="https://entreprisesguineennes.com/establishment/esup-sherbrooke-academy-guinee" target="_blank">-->
<!--        <img src="../../../assets/images/gif/sharebrooke-academy.png" class="responsive-image">-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="slide">-->
<!--      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/804/superieur" target="_blank">-->
<!--        <img src="../../../assets/images/gif/univ_lasource.png" class="responsive-image">-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="slide">-->
<!--      <a href="https://www.annuaireeducationguinee.com/detail-etablissement/2125/superieur" target="_blank">-->
<!--        <img src="../../../assets/images/gif/unc.png" class="responsive-image">-->
<!--      </a>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div class="p-grid p-m-0">
  <div class="p-col">
    <div class="card p-tag" style="width:100%;text-align: left">
      <div class="line-separator"></div>
      <span class="search-text-bold" style="text-align: left;font-size: 1.5rem;"> BIENVENUE ! </span>
      <div class="line-separator"></div>
    </div>
    <br/> <br/>
    <span style="margin-left:20px">
      <i class="pi pi-info-circle"></i>
         Cet annuaire s’appuie sur certaines données ouvertes officielles publiées par le ministère de l’Enseignement Pré-universitaire de l’alphabétisation (MEPUA),
      par le ministère de l’Enseignement technique et de la Formation professionnelle (METFP) et par d'autres sources ouvertes.
      Les informations qui apparaissent ici sont loin d'être une photographie à jour des informations se trouvant sur les sites des ministères précités. La vocation du site va au-delà des objectifs affichés par les ministères.
      À noter que cet annuaire est un site indépendant non affilié au gouvernement.
      </span>
    <br/><br/>
    <span class="titre-trois">L’annuaire de l’Education vous propose</span>
    <br/>
    <span class="search-text-bold">
      <br/>
      ...des informations actualisées sur plus de 10 000 établissements scolaires d'enseignement français publics et privés : primaires, collèges, lycées, supérieurs, professionnels, rectorats depuis les débuts de l'école guinéenne.
    <br/>
      Vous trouverez notamment:
      <br/><br/>
    </span>
    <span>
      <ul>
        <li>Les coordonnées des établissements ;</li>
        <li>Les options proposées et le nombre d’élèves participants ;</li>
        <li>L’équipement numérique ;</li>
        <li>L’effectif d’élèves par niveau ;</li>
        <li>Le choix des langues vivantes par les élèves ;</li>
        <li>Le nombre d’enseignements, la proportion hommes-femmes, la répartition par tranches d’âge et la moyenne d’âge estimée ;</li>
        <li>Les programmes scolaires officiels, par discipline.</li>
        <li>La catégorisation des établissents par statuts (privé - public)</li>
        <li>La recherche par nom, localité (région, prefecture, sous-prefecture etc...)</li>
        <li>La recherche par présence de galéries</li>
      </ul>
    </span>
    <br/>
    <div class="card p-tag" style="width:100%;text-align: left">
      <div class="line-separator"></div>
      <span class="search-text-bold" style="text-align: left;font-size: 1.5rem;"> Ce site bénéficie du soutien des établissements / sponsors listés ci-dessous ! </span>
      <div class="line-separator"></div>
    </div>
    <br/> <br/>
    <span>
      <i class="pi pi-info-circle"></i>
      Nous sommes infiniment reconnaissants envers ces sponsors qui ont choisi de soutenir ce site.Leur générosité nous touche profondément ! <br/><br/>
    </span>
    <div class="p-col-12">
      <div class="p-grid">
        <div class="p-col-12">
          <p-dropdown class="p-fluid" [options]="sponsors" [(ngModel)]="selectedSponsor" optionLabel="nom" [showClear]="false"
                      [filter]="false"
                      placeholder="Veuillez sélectionner un sponsor..."
                      (onChange)="onSponsorChange($event)">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-grid detail-search div-cadre p-m-0">
          <div class="p-text-left h-auto">
            <div style="height: 10px"></div>
            <span class="p-p-0 p-text-uppercase detail-etablissement-nom-text-bold">
              {{etablissement?.nom}} &nbsp; &nbsp;
            </span>
            <div style="height: 10px"></div>
            <div class="grid-line-separator p-p-0 p-m-0"></div>
            <div style="height: 10px"></div>
            <span class="p-tag p-m-1">{{etablissement?.statut}}</span>
            <span class="p-tag p-m-1 p-text-uppercase"> {{etablissement?.effectif}} élèves </span>
            <div style="height: 10px"></div>
            Suivez-nous sur les réseaux sociaux
            <div *ngIf="warningMsg">
              <br/><p-message severity="error" text="Nous n'avons pas trouvé cet établissement sur les réseaux sociaux."></p-message>
            </div>
            <div style="height: 20px" *ngIf="!warningMsg">
              <ul class="icons" style="text-align: left;">
                <li *ngFor="let reseauSocial of etablissement?.reseauxSociaux">
                  <a *ngIf="reseauSocial.nom === 'Facebook'" href="{{reseauSocial.lien}}" target="_blank" class="icon brands fa-facebook"><span class="label">Facebook</span></a>
                  <a *ngIf="reseauSocial.nom === 'Linkedin'" href="{{reseauSocial.lien}}" target="_blank" class="icon brands fa-linkedin"><span class="label">Linkedin</span></a>
                  <a *ngIf="reseauSocial.nom === 'Twitter'" href="{{reseauSocial.lien}}" target="_blank" class="icon brands fa-twitter"><span class="label">Twitter</span></a>
                  <a *ngIf="reseauSocial.nom === 'Youtube'" href="{{reseauSocial.lien}}" target="_blank" class="icon brands fa-youtube"><span class="label">Youtube</span></a>
                </li>
              </ul>
            </div>
            <br/>
            Vous pouvez nous joindre aussi par WhatsApp, e-mail, téléphone ou via notre site web (si disponible).
            <div style="height: 20px">
              <ul class="icons" style="text-align: left;">
                <li *ngIf="!siteWebDisabled"><a href="{{etablissement?.siteWeb}}" target="_blank" class="icon solid fas fa-globe"><span class="label">Site internet</span></a></li>
                <li><a href="tel:{{phoneNumber}}" class="icon solid fa-phone"><span class="label">Téléphone</span></a></li>
                <li><a href="https://api.whatsapp.com/send?phone={{phoneNumber}}" class="icon brands fa-whatsapp"><span class="label">WhatsApp</span></a></li>
                <li><a href="mailto:{{etablissement?.adresse?.email}}" class="icon solid fa-envelope"><span class="label">Email</span></a></li>
              </ul>
            </div>
            <br/>
            <p><a [href]="lien" target="_blank">Pour plus de détails sur cet établissement, <span style="color: #ff5718; font-size: 1.2rem;">cliquez ici.</span></a></p>
          </div>
          <div class="p-text-left">
            <div style="height: 10px"></div>
            <span class="p-m-1 search-text-bold">Coordonnées</span>
            <div style="height: 10px"></div>
            <div class="coordonnes-separator p-p-0 p-m-0"></div>
            <div style="height: 10px"></div>
            <span class="p-m-1">
             <i class="pi pi-home"></i> {{etablissement?.adresse?.district}} - {{etablissement?.sousPrefecture?.nom}}
            </span>
            <br/>
            <span class="p-m-4">
             {{etablissement?.sousPrefecture?.prefecture?.nom}}
            </span>
            <br/>
            <span class="p-m-4">
             {{etablissement?.sousPrefecture?.prefecture?.regionAdministrative?.nom}}
            </span>
            <br/>
            <a href="tel:{{etablissement?.adresse?.telephone}}">
              <span class="p-m-1">
              <i class="pi pi-mobile"></i>
               {{etablissement?.adresse?.telephone}}
             </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-fixed" style="width:340px;justify-content: center;">
    <p-card header="L'annuaire de l'école guinéenne"
            subheader="Chemin de l'école, chemin des possibles" [style]="{width: '330px'}" styleClass="ui-card-shadow">
      <p-header>
        <div class="p-col-12">
          <img src="../../../assets/images/lasource-360.jpg" style="max-width: 100%; height: auto;">
        </div>
        <div class="p-col-0">
        </div>
      </p-header>
      <div style="justify-content: center;">
        «L'école revêt une importance primordiale en tant qu'institution clé de l'éducation, car elle joue un rôle
        essentiel dans la transmission du savoir, le développement des compétences, et la formation du caractère des
        individus. Elle offre un cadre structuré pour l'apprentissage, stimule la curiosité intellectuelle, encourage la
        pensée critique, et favorise l'épanouissement personnel et social des élèves.
        En outre, l'école contribue à la création d'une société instruite et informée, capable de relever les défis du
        monde moderne et de promouvoir le progrès.
        Elle égalise également les opportunités en offrant à tous les enfants, quelle que soit leur origine, un accès à
        l'éducation et une voie vers un avenir meilleur. Ainsi, l'école est un pilier fondamental pour le développement
        individuel et collectif, modelant les générations futures et façonnant un avenir prometteur pour l'humanité.»
        ...chatGPT contribution.
      </div>
    </p-card>
  </div>
  <div class="p-col-fixed" style="width:340px;justify-content: center;">
    <div class="h-max-width">
      <p-carousel [value]="statisticRegionValues" [numVisible]="1" [numScroll]="1" class="h-max-width"
                  [circular]="true" style="justify-content: center;">
        <ng-template let-region pTemplate="item">
          <table style="justify-content: center;">
            <caption>{{region.nom}}</caption>
            <tr>
              <!--              <th scope="col">Préfecture</th>-->
              <th scope="col" colspan="3">Nombre d‘établissements scolaires par niveau</th>
            </tr>
            <tr *ngFor="let prefecture of region.prefectureList">
              <!--              <th scope="row">{{prefecture.nom}}</th>-->
              <td colspan=3 align="center" class="center-table">
                {{prefecture.nomcourt}}
                <table>
                  <tr>
                    <td>Primaire</td>
                    <td>{{prefecture.nbrePrimaire}}</td>
                  </tr>
                  <tr>
                    <td>Collège</td>
                    <td>{{prefecture.nbreCollege}}</td>
                  </tr>
                  <tr>
                    <td>Lycée</td>
                    <td>{{prefecture.nbreLycee}}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <!--<div *ngIf="region.nom != 'Région de Nzérékoré'">-->
          <!--<img src="../../../assets/images/proverbe-africain-sur-l-ecole_3.jpg" class="h-auto">-->
          <!--</div>-->
        </ng-template>
      </p-carousel>
    </div>
    <br/>
  </div>
</div>
