<div style="height: 10px"></div>
<div class="p-d-flex p-col-12 div-cadre detail-search p-text-right">
  <div class="p-col p-text-right">
    <p class="search-text-bold p-text-uppercase p-text-center">Création ou modification d'une sous-préfecture </p>
  </div>
</div>
<app-localite-datas></app-localite-datas>
<button pButton pRipple type="submit" label="{{labelSaveButton}}" class="p-button-rounded p-m-1"
        (click)="onSubmit()"></button>
<button pButton pRipple type="button" label="{{labelCancelButton}}"
        class="p-button-rounded p-button-secondary p-m-1" (click)="onReset()"></button>
