import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SearchQuery} from "../../models/searchquery";
import {Professionnel} from "../../models/professionnel/professionnel.model";

@Injectable({
  providedIn: 'root'
})
export class ProfessionnelService {

  constructor(protected http: HttpClient) { }

  getProfessionnels(): Observable<Professionnel[]> {
    return this.http.get<Professionnel[]>('https://api.annuaireeducationguinee.com/letudiant/professionnels');
  }

  findProfessionnel(id: number): Observable<Professionnel> {
    return this.http.get<Professionnel>("https://api.annuaireeducationguinee.com/letudiant/professionnel/" + id);
  }

  getProfessionnelsFromQuery(searchQuery: SearchQuery): Observable<Professionnel[]> {
    return this.http.post<Professionnel[]>('https://api.annuaireeducationguinee.com/letudiant/professionnels/criteres/search', searchQuery);
  }

}
