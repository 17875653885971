import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Superieur} from '../../models/superieur/superieur.model';
import {SearchQuery} from "../../models/searchquery";
import {Primaire} from "../../models/primaire/primaire";

@Injectable({
  providedIn: 'root'
})
export class SuperieurService {

  constructor(protected http: HttpClient) { }

  getSuperieurs(): Observable<Superieur[]> {
    return this.http.get<Superieur[]>('https://api.annuaireeducationguinee.com/letudiant/superieurs');
  }

  findSuperieur(id: number): Observable<Superieur> {
    return this.http.get<Superieur>("https://api.annuaireeducationguinee.com/letudiant/superieur/" + id);
  }

  getSuperieursFromQuery(searchQuery: SearchQuery): Observable<Superieur[]> {
    return this.http.post<Superieur[]>('https://api.annuaireeducationguinee.com/letudiant/superieurs/criteres/search', searchQuery);
  }

  sponsors(): Observable<Superieur[]> {
     return this.http.get<Superieur[]>('https://api.annuaireeducationguinee.com/letudiant/superieurs/sponsors');
  }

}
