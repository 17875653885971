import {Component, OnInit, ViewChild} from '@angular/core';
import {Lycee} from "../../models/lycee/lycee";
import {LyceeService} from "../../services/lycee/lycee.service";
import {SearchQueryComponent} from "../commun/search-query/search-query.component";

@Component({
  selector: 'app-lycee',
  templateUrl: './lycee.component.html',
  styleUrls: ['./lycee.component.css']
})
export class LyceeComponent implements OnInit {

  sortOrder: number;
  sortField: string;
  lycees: Lycee[];
  lyceesFromQuery: Lycee[];
  progressBarValue: boolean = true;
  @ViewChild
  (SearchQueryComponent, {static: true}) searchQueryComponent;

  constructor(protected lyceeService: LyceeService) {

  }

  ngOnInit(): void {
    this.loadLycees();
    this.searchQueryComponent.typeEtablissement = "lycées";
  }

  loadLycees() {
    return this.lyceeService.getLycees().subscribe((data) => {
      this.lycees = data;
      this.lyceesFromQuery = data;
      this.searchQueryComponent.etablissements = data;
      this.searchQueryComponent.updateSelectedLocaliteValue();
      this.searchQueryComponent.nbreEvaluations = data.filter(etablissement => etablissement.evaluated === true).length;
      this.progressBarValue = false;
    });
  }

  loadLyceesFromQuery() {
    this.progressBarValue = true;
    this.searchQueryComponent.updateParams();
    console.log(JSON.stringify(this.searchQueryComponent.searchQuery));
    return this.lyceeService.getLyceesFromQuery(this.searchQueryComponent.searchQuery).subscribe((data) => {
      this.lyceesFromQuery = data;
      this.searchQueryComponent.updateSelectedLocaliteValue();
      this.progressBarValue = false;
    }, error => {
      console.error('There was an error!', error);
      console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }


}
