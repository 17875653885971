import {HttpClient} from "@angular/common/http";
import {httpOptions} from "../../pages/app.constant";
import {Observable} from "rxjs/index";
import {SousPrefecture} from "../../models/commun/sous-prefecture";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SousPrefectureService {

  constructor(protected http: HttpClient) { }

  getSousPrefectures(): Observable<SousPrefecture[]> {
    return this.http.get<SousPrefecture[]>('https://api.annuaireeducationguinee.com/letudiant/prefectures');
  }

  findSousPrefecture(id: number): Observable<SousPrefecture> {
    return this.http.get<SousPrefecture>("https://api.annuaireeducationguinee.com/letudiant/superieur/" + id);
  }

  sousPrefectures(idPrefecture: number): Observable<SousPrefecture[]> {
    return this.http.get<SousPrefecture[]>("https://api.annuaireeducationguinee.com/letudiant/sous-prefectures/prefecture/" + idPrefecture);
  }

  addNewSousPrefecture(sousPrefecture: SousPrefecture): Observable<Object> {
    console.log(" 888888888888888 " + JSON.stringify(sousPrefecture));
    return this.http.post('https://api.annuaireeducationguinee.com/letudiant/sous-prefecture/save', sousPrefecture, httpOptions);
  }

}
