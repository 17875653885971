import {Component, OnInit, ViewChild} from '@angular/core';
import {LocaliteDatasComponent} from "../localite-datas/localite-datas.component";
import {RegionService} from "../../../services/region/region.service";
import {RegionAdministrative} from "../../../models/commun/region-administrative";

@Component({
  selector: 'app-create-and-update-region',
  templateUrl: './create-and-update-region.component.html',
  styleUrls: ['./create-and-update-region.component.css']
})
export class CreateAndUpdateRegionComponent implements OnInit {

  labelCancelButton: string = "Cancel";
  labelSaveButton: string = "Enregister";
  @ViewChild
  (LocaliteDatasComponent, {static: true}) localiteDatasComponent;

  constructor(private regionService: RegionService) {

  }

  ngOnInit(): void {
    this.updateTitles();
  }

  onSubmit() {
    this.localiteDatasComponent.submitted = true;
    this.localiteDatasComponent.localiteForm.get('selectValue').clearValidators();
    this.localiteDatasComponent.localiteForm.get('selectValue').updateValueAndValidity();
    if (this.localiteDatasComponent.localiteForm.invalid) {
      return;
    }
    // Le formulaire est valide et l'ajout est possibe
    this.addRegion();

  }

  addRegion() {
    let region: RegionAdministrative;
    let nombrePrefecture = this.localiteDatasComponent.localiteForm.value.nbreSousLocalites;
    region = this.localiteDatasComponent.localiteForm.value;
    region.nbrePrefectures = nombrePrefecture;
    this.regionService.addNewRegion(region).subscribe(value => {
      this.localiteDatasComponent.messageSuccess = 'La région a été enregistrée avec succès';
      this.localiteDatasComponent.sucessMessage = true;
    }, error => {
      console.log('FAIL to localite axe!', error);
      this.localiteDatasComponent.sucessMessage = false;
    }, () => {
      console.log('POST axe - now completed.');
    });
  }

  updateTitles() {
    this.localiteDatasComponent.nomLocalite = 'Nom de la région';
    this.localiteDatasComponent.codeLocalite = 'Code de la région';
    this.localiteDatasComponent.pHeaderTitle = 'Création de la région';
    this.localiteDatasComponent.nbreSousLocalite = 'Nombre de sous-régions';
  }

  onReset() {
    this.localiteDatasComponent.onReset();
    this.localiteDatasComponent.sucessMessage = false;
  }
}
