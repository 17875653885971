import {Component, OnInit} from '@angular/core';
import {RegionService} from "../../services/region/region.service";
import {StatisticAccueil} from "../../models/commun/statistic-accueil";
import {Sponsor} from "../../models/sponsor.model";
import {Superieur} from "../../models/superieur/superieur.model";
import {SuperieurService} from "../../services/superieur/superieur.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  responsiveOptions;
  progressBarValue: boolean = true;
  sponsors: Sponsor[];
  selectedSponsor: any | undefined;
  etablissement: any;
  selectedSponsors: any[];
  statisticRegionValues: StatisticAccueil[];
  expanded: string = 'pi pi-chevron-down';
  siteWebDisabled: boolean = false;
  warningMsg: boolean = false;
  phoneNumber: string = "00224622735167";
  lien: string = 'https://www.annuaireeducationguinee.com/home';

  constructor(private regionService: RegionService, protected superieurService: SuperieurService) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this.findRegions();
    this.ourSponsors();
  }

  findRegions() {
    this.regionService.getStatisticRegionValues().subscribe((data) => {
      console.log(JSON.stringify(data));
      this.statisticRegionValues = data;
      this.progressBarValue = false;
    });
  }

  ourSponsors() {
     this.superieurService.sponsors().subscribe((data) => {
     this.sponsors = data;
     this.etablissement = this.sponsors.find(unc => unc.id == 2125);
     this.concatLien(this.etablissement.id);
     this.loadStatutSiteWeb();
     this.phoneNumber = this.formatPhoneNumber(this.etablissement?.adresse?.telephone);
     });
  }

  loadStatutSiteWeb() {
      if (this.etablissement.siteWeb === 'N/A') {
        this.siteWebDisabled = true;
      }
      this.warningMsg = !this.etablissement?.reseauxSociaux.length;
  }

   formatPhoneNumber(phoneNumber: string): string {
        // Supprimer les parenthèses et les espaces du numéro de téléphone
        return phoneNumber.replace(/[\(\) ]/g, '');
   }

   onSponsorChange(event: any) {
     console.log('Selected sponsor:', event.value);
     if (event.value != null) {
      this.etablissement = event.value;
      this.loadStatutSiteWeb();
      this.concatLien(this.etablissement.id);
     }
   }

   concatLien(id: any) {
     this.lien = 'https://www.annuaireeducationguinee.com/detail-etablissement/'+id+'/superieur';
   }

}
