<br/>
<!--<div class="p-grid" style="justify-content: center">-->
<!--  <h1 class="tw-font-heading tw-text-4xl tw-leading-8 sm:tw-text-5xl sm:tw-leading-9 tw-mb-4 sm:tw-mb-6">-->
<!--    Trouvez votre collège avec l'annuaire des collèges-->
<!--  </h1>-->
<!--</div>-->
<div class="p-grid" style="justify-content: center">
  <h2>
    Liste des établissements scolaires primaires par sous-préfectures ou leurs équivalents<br/>
  </h2>
</div>
<div class="p-grid" style="justify-content: left">
  <div class="p-col-12 p-md-2 p-text-left">
    <p-tree [value]="sousPrefectures0" [filter]="true">
      <ng-template let-node pTemplate="url">
        <a [href]="node.data" target="_blank" rel="noopener noreferrer">
          {{ node.label }}
        </a>
      </ng-template>
      <ng-template let-node pTemplate="default">
        <b>{{ node.label }}</b>
      </ng-template>
    </p-tree>
<!--    <br/>Almamya-->
<!--    <br/>Boulbinet-->
<!--    <br/>Coronthie-->
<!--    <br/>Fotoba-->
<!--    <br/>Kassa-->
<!--    <br/>Kouléwondy-->
<!--    <br/>Manquepas-->
<!--    <br/>Sandervalia-->
<!--    <br/>Sans-fil-->
<!--    <br/>Témitaye-->
<!--    <br/>Tombo-->
<!--    <br/>Belle-vue école-->
<!--    <br/>Belle-vue-marché-->
<!--    <br/>Camayenne-->
<!--    <br/>Cameroun-->
<!--    <br/>Dixinn-cité1-->
<!--    <br/>Dixinn-cité2-->
<!--    <br/>Dixinn-gare-->
<!--    <br/>Dixinn-gare-rails-->
<!--    <br/>Dixinn-mosquée-->
<!--    <br/>Dixinn-port-->
<!--    <br/>Dixinn-Bora-->
<!--    <br/>Hafia 1-->
<!--    <br/>Hafia 2-->
<!--    <br/>Hafia-minière-->
<!--    <br/>Hafia-mosquée-->
<!--    <br/>Kénien-->
<!--    <br/>Landréah-->
<!--    <br/>Minière-cité-->
<!--    <br/>Bonfi-->
<!--    <br/>Bonfi-marché-->
<!--    <br/>Bonfi-Routière-->
<!--    <br/>Carrière-->
<!--    <br/>Carrière-centre-->
<!--    <br/>Boussoura-->
<!--    <br/>Coléah-centre-->
<!--    <br/>Coléah-cité-->
<!--    <br/>Coléah-Domino-->
<!--    <br/>Coléah-Imprimerie-->
<!--    <br/>Hermakönon / Hérémakonon Mosquée-->
<!--    <br/>Hermakönon / Hérémakonon Permanence-->
<!--    <br/>Lanséboudji / Lancéboudji-->
<!--    <br/>Madina-centre-->
<!--    <br/>Madina-cité-->
<!--    <br/>Madina-école-->
<!--    <br/>Madina-marché-->
<!--    <br/>Madina-mosquée-->
<!--    <br/>Mafanco-->
<!--    <br/>Mafonco-centre-->
<!--    <br/>Matam-->
<!--    <br/>Matam-Centre-->
<!--    <br/>Matam-lido-->
<!--    <br/>Touguiwondy-->
<!--    <br/>Aviation-->
<!--    <br/>Béanzin/Behanzin-->
<!--    <br/>Carrière-->
<!--    <br/>Camp Alpha Yaya Diallo-->
<!--    <br/>Citée de l'air-->
<!--    <br/>Dabompa-->
<!--    <br/>Dabompa Plateau-->
<!--    <br/>Dabondy 1-->
<!--    <br/>Dabondy 2-->
<!--    <br/>Dabondy 3-->
<!--    <br/>Dabondy-école-->
<!--    <br/>Dabondy-rails-->
<!--    <br/>Dar-es-salam-->
<!--    <br/>Enta Marché-->
<!--    <br/>Enta Nord-->
<!--    <br/>Gbéssia-centre-->
<!--    <br/>Gbéssia-cité 1-->
<!--    <br/>Gbéssia-cité 2-->
<!--    <br/>Gbéssia-cité 3-->
<!--    <br/>Gbéssia-école-->
<!--    <br/>Gbéssia-port 1-->
<!--    <br/>Gbéssia-port 2-->
<!--    <br/>Kissosso-->
<!--    <br/>Kissosso-Plateau-->
<!--    <br/>Lansanaya-Barrage-->
<!--    <br/>Lansanaya-Takoui-->
<!--    <br/>Matoto-centre-->
<!--    <br/>Matoto-Khabitayah-->
<!--    <br/>Matoto-marché-->
<!--    <br/>Sangoyah-->
<!--    <br/>Sangoyah-Marché-->
<!--    <br/>Sangoyah-Mosquée-->
<!--    <br/>Simbaya 1-->
<!--    <br/>Simbaya 2-->
<!--    <br/>Simbaya école-->
<!--    <br/>Tanéné-marché-->
<!--    <br/>Tanéné-mosquée-->
<!--    <br/>Tombolia-->
<!--    <br/>Tombolia-Plateau-->
<!--    <br/>Yimbaya-école-->
<!--    <br/>Yimbaya-permanence-->
<!--    <br/>Yimbaya-Port-->
<!--    <br/>Yimbaya-tannerie-->
<!--    <br/>Bantounka 1-->
<!--    <br/>Bantounka 2-->
<!--    <br/>Bambeto-->
<!--    <br/>Bambeto-->
  </div>
  <div class="p-col-12 p-md-2 p-text-left">
    <p-tree [value]="sousPrefectures1" [filter]="true">
      <ng-template let-node pTemplate="url">
        <a [href]="node.data" target="_blank" rel="noopener noreferrer">
          {{ node.label }}
        </a>
      </ng-template>
      <ng-template let-node pTemplate="default">
        <b>{{ node.label }}</b>
      </ng-template>
    </p-tree>
<!--    <br/>Cité Enco 5-->
<!--    <br/>Cosa-->
<!--    <br/>Dar-es-salam-->
<!--    <br/>Hamdallaye 1-->
<!--    <br/>Hamdallaye 2-->
<!--    <br/>Hamdallaye mosquée-->
<!--    <br/>Kaporo Centre-->
<!--    <br/>Simbaya-gare-->
<!--    <br/>Sonfonia-Casse-->
<!--    <br/>Sonfonia-Centre 1-->
<!--    <br/>Sonfonia-Centre 2-->
<!--    <br/>Sonfonia-gare 1-->
<!--    <br/>Sonfonia-gare 2-->
<!--    <br/>Soumabossia/Simanbossia-->
<!--    <br/>Taouyah-->
<!--    <br/>Wanindara 1-->
<!--    <br/>Wanindara 2-->
<!--    <br/>Wanindara 3-->
<!--    <br/>Wareah / Wareya-->
<!--    <br/>Yattayah-->
<!--    <br/>Yattayah-Centre-->
<!--    <br/>Yattaya Fossidè-->
<!--    <br/>Yembéya-->
<!--    <br/>Boffa-Centre-->
<!--    <br/>Colia-->
<!--    <br/>Douprou-->
<!--    <br/>Koba-Tatema-->
<!--    <br/>Lisso-->
<!--    <br/>Mankountan-->
<!--    <br/>Tamita-->
<!--    <br/>Tougnifili-->
<!--    <br/>Bintimodiya / Bintimodia-->
<!--    <br/>Boké-Centre-->
<!--    <br/>Dabiss-->
<!--    <br/>Kamsar-->
<!--    <br/>Kanfarandé-->
<!--    <br/>Kolaboui-->
<!--    <br/>Malapouya-->
<!--    <br/>Sangaredi-->
<!--    <br/>Sansalé-->
<!--    <br/>Tanènè-->
<!--    <br/>Foulamory-->
<!--    <br/>Gaoual-Centre-->
<!--    <br/>Kakony-->
<!--    <br/>Koumbia-->
<!--    <br/>Kounsitel-->
<!--    <br/>Malanta-->
<!--    <br/>Touba-->
<!--    <br/>Wendou M'Bour-->
<!--    <br/>Guingan-->
<!--    <br/>Kamaby-->
<!--    <br/>Koundara-Centre-->
<!--    <br/>Sambailo-->
<!--    <br/>Sareboido-->
<!--    <br/>Termesse-->
<!--    <br/>Youkounkoun-->
<!--    <br/>Baguinet-->
<!--    <br/>Banguingny-->
<!--    <br/>Fria-Centre-->
<!--    <br/>Tormelin-->
<!--    <br/>Coyah-Centre-->
<!--    <br/>Kouriah-->
<!--    <br/>Manéah-->
<!--    <br/>Wonkifong-->
<!--    <br/>Badi-->
<!--    <br/>Dubreka-Centre-->
<!--    <br/>Faléssadé-->
<!--    <br/>Khorira-->
<!--    <br/>Tanènè-->
<!--    <br/>Tondon-->
<!--    <br/>Wassou / Ouassou-->
<!--    <br/>Alassoya-->
<!--    <br/>Benty-->
<!--    <br/>Farmoriah-->
<!--    <br/>Forécariah-Centre-->
<!--    <br/>Kaback-->
<!--    <br/>Kakossa-->
<!--    <br/>Kallia / KALIAH-->
<!--    <br/>Maferenya-->
<!--    <br/>Moussaya-->
<!--    <br/>Sikhourou-->
<!--    <br/>Bangouyah-->
<!--    <br/>Damankanyah / Damakhanya-->
<!--    <br/>Friguiagbé-->
<!--    <br/>Kindia-Centre-->
<!--    <br/>Kolente-->
<!--    <br/>Madina Oula-->
<!--    <br/>Mambiya / Mambia-->
<!--    <br/>Molota-->
<!--    <br/>Samayah-->
<!--    <br/>Sougueta-->
<!--    <br/>Bourouwal / BROUWAL-->
<!--    <br/>Daramagnaky-->
<!--    <br/>Gougoudjé-->
<!--    <br/>Koba-->
<!--    <br/>Kollet-->
<!--    <br/>Konsotamy / KONSOTAMI-->
<!--    <br/>Missira-->
<!--    <br/>Santou-->
<!--    <br/>Sarékaly-->
  </div>
  <div class="p-col-12 p-md-2 p-text-left">
    <p-tree [value]="sousPrefectures2" [filter]="true">
      <ng-template let-node pTemplate="url">
        <a [href]="node.data" target="_blank" rel="noopener noreferrer">
          {{ node.label }}
        </a>
      </ng-template>
      <ng-template let-node pTemplate="default">
        <b>{{ node.label }}</b>
      </ng-template>
    </p-tree>
<!--    <br/>Sinta-->
<!--    <br/>Sogolon-->
<!--    <br/>Tarihoye-->
<!--    <br/>Télimélé-Centre-->
<!--    <br/>Thionthian-->
<!--    <br/>Bodié-->
<!--    <br/>Dalaba-Centre-->
<!--    <br/>Ditinn-->
<!--    <br/>Kaala-->
<!--    <br/>Kankalabé-->
<!--    <br/>Kébali-->
<!--    <br/>Koba-->
<!--    <br/>Mafara-->
<!--    <br/>Mitty-->
<!--    <br/>Mombéyah-->
<!--    <br/>Bouliwel-->
<!--    <br/>Dounet-->
<!--    <br/>Gongoret-->
<!--    <br/>Kégnéko-->
<!--    <br/>Konkouré-->
<!--    <br/>Mamou-Centre-->
<!--    <br/>Nyagara-->
<!--    <br/>Ouré-Kaba-->
<!--    <br/>Porédaka-->
<!--    <br/>Saramoussaya-->
<!--    <br/>Soyah-->
<!--    <br/>Téguéréya-->
<!--    <br/>Timbo-->
<!--    <br/>Tolo-->
<!--    <br/>Bantignel-->
<!--    <br/>Bourouwal-Tappé-->
<!--    <br/>Donghol-Touma-->
<!--    <br/>Gongore-->
<!--    <br/>Ley-Miro-->
<!--    <br/>Maci-->
<!--    <br/>Ninguélandé-->
<!--    <br/>Pita-Centre-->
<!--    <br/>Sangaréah-->
<!--    <br/>Sintali-->
<!--    <br/>Timbi Madina-->
<!--    <br/>Timbi-Touny-->
<!--    <br/>Arfamoussaya-->
<!--    <br/>Banko-->
<!--    <br/>Bissikrima-->
<!--    <br/>Dabola-Centre-->
<!--    <br/>Dogomet-->
<!--    <br/>Kankama-->
<!--    <br/>Kindoye-->
<!--    <br/>Konindou-->
<!--    <br/>Ndéma / N'déma-->
<!--    <br/>Banora-->
<!--    <br/>Dialakoro-->
<!--    <br/>Diatiféré-->
<!--    <br/>Dinguiraye-Centre-->
<!--    <br/>Gagnakali-->
<!--    <br/>Kalinko-->
<!--    <br/>Lansanya-->
<!--    <br/>Sélouma-->
<!--    <br/>Banian-->
<!--    <br/>Beindou-->
<!--    <br/>Faranah-Centre-->
<!--    <br/>Gnaléah-->
<!--    <br/>Hérémakonon-->
<!--    <br/>Kobikoro-->
<!--    <br/>Marela / Maréla-->
<!--    <br/>Passayah-->
<!--    <br/>Sandéniah-->
<!--    <br/>Songoyah-->
<!--    <br/>Tindo-->
<!--    <br/>Tiro-->
<!--    <br/>Albadaria-->
<!--    <br/>Banama-->
<!--    <br/>Bardou-->
<!--    <br/>Beindou-->
<!--    <br/>Fermessadou-Pombo-->
<!--    <br/>Firawa-->
<!--    <br/>Gbangbadou-->
<!--    <br/>Kissidougou-Centre-->
<!--    <br/>Kondiadou-->
<!--    <br/>Manfran-->
<!--    <br/>Sangardo-->
<!--    <br/>Yendé-Millimou-->
<!--    <br/>Yombiro-->
<!--    <br/>Balandougou-->
<!--    <br/>Bate-Nafadji-->
<!--    <br/>Boula-->
<!--    <br/>Gbérédou-Baranama-->
<!--    <br/>Kankan-Centre-->
<!--    <br/>Karfamoriyah / Karfamoriah / KARIFAMORIAH-->
<!--    <br/>Koumban-->
<!--    <br/>Mamouroudou-->
<!--    <br/>Missamana-->
<!--    <br/>Moribayah-->
<!--    <br/>Sabadou-Baranama-->
<!--    <br/>Tinti-Oulen-->
<!--    <br/>Tokounou-->
<!--    <br/>Banankoro-->
<!--    <br/>Damaro-->
<!--    <br/>Kérouané-Centre-->
<!--    <br/>Komodou-->
  </div>
  <div class="p-col-12 p-md-2 p-text-left">
    <p-tree [value]="sousPrefectures2" [filter]="true">
      <ng-template let-node pTemplate="url">
        <a [href]="node.data" target="_blank" rel="noopener noreferrer">
          {{ node.label }}
        </a>
      </ng-template>
      <ng-template let-node pTemplate="default">
        <b>{{ node.label }}</b>
      </ng-template>
    </p-tree>
<!--    <br/>Kounsankoro-->
<!--    <br/>Linko-->
<!--    <br/>Sibiribaro-->
<!--    <br/>Soromaya-->
<!--    <br/>Babila-->
<!--    <br/>Balato-->
<!--    <br/>Banfélé-->
<!--    <br/>Baro-->
<!--    <br/>Cisséla-->
<!--    <br/>Douako-->
<!--    <br/>Doura-->
<!--    <br/>Kiniéro-->
<!--    <br/>Komola-Koura-->
<!--    <br/>Koumana-->
<!--    <br/>Kouroussa-Centre-->
<!--    <br/>Sanguiana-->
<!--    <br/>Balandougouba-->
<!--    <br/>Dialakoro-->
<!--    <br/>Faralako-->
<!--    <br/>Kantoumania-->
<!--    <br/>Kiniéran-->
<!--    <br/>Koundian-->
<!--    <br/>Koundianakoro / Kondianakoro-->
<!--    <br/>Mandiana-Centre-->
<!--    <br/>Morodou-->
<!--    <br/>Niantania / Niantanina-->
<!--    <br/>Saladou-->
<!--    <br/>Sansando-->
<!--    <br/>Bankon-->
<!--    <br/>Doko-->
<!--    <br/>Franwalia-->
<!--    <br/>Kiniébakoura-->
<!--    <br/>Kintinian-->
<!--    <br/>Maléa / MALEAH-->
<!--    <br/>Naboun-->
<!--    <br/>Niagassola-->
<!--    <br/>Niandankoro-->
<!--    <br/>Norassoba-->
<!--    <br/>Nounkounkan-->
<!--    <br/>Siguiri-Centre-->
<!--    <br/>Siguirini-->
<!--    <br/>Fafaya-->
<!--    <br/>Gadha-Woundou-->
<!--    <br/>Koubia-Centre-->
<!--    <br/>Matakaou-->
<!--    <br/>Missira-->
<!--    <br/>Pilimini-->
<!--    <br/>Dalein-->
<!--    <br/>Daralabe-->
<!--    <br/>Diari-->
<!--    <br/>Dionfo-->
<!--    <br/>Garambé-->
<!--    <br/>Hafia-->
<!--    <br/>Kaalan / KALAN-->
<!--    <br/>Kouramangui-->
<!--    <br/>Labé-Centre-->
<!--    <br/>Noussy-->
<!--    <br/>Popodara-->
<!--    <br/>Sannou / Sannoun-->
<!--    <br/>Taranbaly-->
<!--    <br/>Tountouroun-->
<!--    <br/>Balaya-->
<!--    <br/>Djountou/DIOUNTOU-->
<!--    <br/>Herico-->
<!--    <br/>Korbe-->
<!--    <br/>Lafou-->
<!--    <br/>Lélouma-Centre-->
<!--    <br/>Linsansaran-->
<!--    <br/>Manda-->
<!--    <br/>Parawol-->
<!--    <br/>Sagalé-->
<!--    <br/>Tyanguel-Bori-->
<!--    <br/>Balaki-->
<!--    <br/>Donghol Sigon-->
<!--    <br/>Dougountouny-->
<!--    <br/>Fougou-->
<!--    <br/>Gayah-->
<!--    <br/>Hidayatou-->
<!--    <br/>Lébékéré-->
<!--    <br/>Madina Wora-->
<!--    <br/>Mali-Centre-->
<!--    <br/>Salambande-->
<!--    <br/>Téliré-->
<!--    <br/>Touba-->
<!--    <br/>Yembereng / Yembering-->
<!--    <br/>Fatako-->
<!--    <br/>Fello Koundoua-->
<!--    <br/>Kansangui-->
<!--    <br/>Kolangui-->
<!--    <br/>Kollet-->
<!--    <br/>Konah-->
<!--    <br/>Kouratongo-->
<!--    <br/>Koïn / Koyin-->
<!--    <br/>Tangaly-->
<!--    <br/>Tougué-Centre-->
<!--    <br/>Beyla-Centre-->
<!--    <br/>Boola-->
<!--    <br/>Diaragbèla/Diarraguerela-->
<!--    <br/>Diassodou-->
<!--    <br/>Fouala-->
  </div>
  <div class="p-col-12 p-md-2 p-text-left">
    <p-tree [value]="sousPrefectures2" [filter]="true">
      <ng-template let-node pTemplate="url">
        <a [href]="node.data" target="_blank" rel="noopener noreferrer">
          {{ node.label }}
        </a>
      </ng-template>
      <ng-template let-node pTemplate="default">
        <b>{{ node.label }}</b>
      </ng-template>
    </p-tree>
<!--    <br/>Gbackédou-->
<!--    <br/>Gbèssoba-->
<!--    <br/>Karala-->
<!--    <br/>Koumandou-->
<!--    <br/>Moussadou-->
<!--    <br/>Nionsomoridou-->
<!--    <br/>Samana-->
<!--    <br/>Sinko-->
<!--    <br/>Sokourala-->
<!--    <br/>Bolodou-->
<!--    <br/>Fangamadou-->
<!--    <br/>Guéckédou-Centre-->
<!--    <br/>Guendembou-->
<!--    <br/>Kassadou-->
<!--    <br/>Koundou-->
<!--    <br/>Nongoa-->
<!--    <br/>Ouéndé-Kénéma-->
<!--    <br/>Tékoulo-->
<!--    <br/>Terméssadou Djibo-->
<!--    <br/>Bossou-->
<!--    <br/>Foumbadou-->
<!--    <br/>Gama Bèrèma-->
<!--    <br/>Guéassou-->
<!--    <br/>Kokota-->
<!--    <br/>Lainé-->
<!--    <br/>Lola-Centre-->
<!--    <br/>N'Zoo-->
<!--    <br/>Tounkarata-->
<!--    <br/>Balizia-->
<!--    <br/>Binikala-->
<!--    <br/>Bofossou-->
<!--    <br/>Daro-->
<!--    <br/>Fassankoni-->
<!--    <br/>Kouankan-->
<!--    <br/>Koyamah-->
<!--    <br/>Macenta-Centre (Kamandou Koura, ...)-->
<!--    <br/>N'Zébéla-->
<!--    <br/>Ourémai/OREMAI-->
<!--    <br/>Panziazou-->
<!--    <br/>Sengbédou/Semgbédou-->
<!--    <br/>Sérédou-->
<!--    <br/>Vassérédou/Vasérédou/VASERIDOU-->
<!--    <br/>Watanka-->
<!--    <br/>Bounouma/BOUNOUNA-->
<!--    <br/>Gouécké-->
<!--    <br/>Kobéla-->
<!--    <br/>Koropara-->
<!--    <br/>Koulé-->
<!--    <br/>Palé-->
<!--    <br/>Nzérékoré-Centre-->
<!--    <br/>Samoé-->
<!--    <br/>Soulouta-->
<!--    <br/>Womey-->
<!--    <br/>Yalenzou-->
<!--    <br/>Banié-->
<!--    <br/>Bheeta / Bhéta-->
<!--    <br/>Bignamou-->
<!--    <br/>Bowé-->
<!--    <br/>Djécké-->
<!--    <br/>Péla-->
<!--    <br/>Yomou-Centre-->
  </div>
  <div class="p-col-12 p-md-2 p-text-left">
    <p-tree [value]="sousPrefectures2" [filter]="true">
      <ng-template let-node pTemplate="url">
        <a [href]="node.data" target="_blank" rel="noopener noreferrer">
          {{ node.label }}
        </a>
      </ng-template>
      <ng-template let-node pTemplate="default">
        <b>{{ node.label }}</b>
      </ng-template>
    </p-tree>
    <!--    <br/>Gbackédou-->
    <!--    <br/>Gbèssoba-->
    <!--    <br/>Karala-->
    <!--    <br/>Koumandou-->
    <!--    <br/>Moussadou-->
    <!--    <br/>Nionsomoridou-->
    <!--    <br/>Samana-->
    <!--    <br/>Sinko-->
    <!--    <br/>Sokourala-->
    <!--    <br/>Bolodou-->
    <!--    <br/>Fangamadou-->
    <!--    <br/>Guéckédou-Centre-->
    <!--    <br/>Guendembou-->
    <!--    <br/>Kassadou-->
    <!--    <br/>Koundou-->
    <!--    <br/>Nongoa-->
    <!--    <br/>Ouéndé-Kénéma-->
    <!--    <br/>Tékoulo-->
    <!--    <br/>Terméssadou Djibo-->
    <!--    <br/>Bossou-->
    <!--    <br/>Foumbadou-->
    <!--    <br/>Gama Bèrèma-->
    <!--    <br/>Guéassou-->
    <!--    <br/>Kokota-->
    <!--    <br/>Lainé-->
    <!--    <br/>Lola-Centre-->
    <!--    <br/>N'Zoo-->
    <!--    <br/>Tounkarata-->
    <!--    <br/>Balizia-->
    <!--    <br/>Binikala-->
    <!--    <br/>Bofossou-->
    <!--    <br/>Daro-->
    <!--    <br/>Fassankoni-->
    <!--    <br/>Kouankan-->
    <!--    <br/>Koyamah-->
    <!--    <br/>Macenta-Centre (Kamandou Koura, ...)-->
    <!--    <br/>N'Zébéla-->
    <!--    <br/>Ourémai/OREMAI-->
    <!--    <br/>Panziazou-->
    <!--    <br/>Sengbédou/Semgbédou-->
    <!--    <br/>Sérédou-->
    <!--    <br/>Vassérédou/Vasérédou/VASERIDOU-->
    <!--    <br/>Watanka-->
    <!--    <br/>Bounouma/BOUNOUNA-->
    <!--    <br/>Gouécké-->
    <!--    <br/>Kobéla-->
    <!--    <br/>Koropara-->
    <!--    <br/>Koulé-->
    <!--    <br/>Palé-->
    <!--    <br/>Nzérékoré-Centre-->
    <!--    <br/>Samoé-->
    <!--    <br/>Soulouta-->
    <!--    <br/>Womey-->
    <!--    <br/>Yalenzou-->
    <!--    <br/>Banié-->
    <!--    <br/>Bheeta / Bhéta-->
    <!--    <br/>Bignamou-->
    <!--    <br/>Bowé-->
    <!--    <br/>Djécké-->
    <!--    <br/>Péla-->
    <!--    <br/>Yomou-Centre-->
  </div>
</div>

