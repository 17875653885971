import {Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {Primaire} from "../../models/primaire/primaire";
import {PrimaireService} from "../../services/primaire/primaire.service";
import {SearchQueryComponent} from "../commun/search-query/search-query.component";
import { TreeNode } from 'primeng/api';
import { TreeModule } from 'primeng/tree';


@Component({
  selector: 'app-primaires',
  templateUrl: './primaires.component.html',
  styleUrls: ['./primaires.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PrimairesComponent implements OnInit {

  sortOrder: number;
  sortField: string;
  primaires: Primaire[];
  primairesFromQuery: Primaire[];
  progressBarValue: boolean = true;
  @ViewChild
  (SearchQueryComponent, {static: true}) searchQueryComponent;
  files!: TreeNode[];
  files00!: TreeNode[];
  nodes!: TreeNode[];
  sousPrefectures0!: TreeNode[];
  sousPrefectures1!: TreeNode[];
  sousPrefectures2!: TreeNode[];

  constructor(protected primaireService: PrimaireService) {

  }

  ngOnInit(): void {
    this.loadPrimaires();
  }

    loadPrimaires() {

      this.sousPrefectures0 = [
                        {
                            key: '0',
                            label: 'Almamya',
                            children: [
                                { key: '0-0', label: 'E.P Almamya/Almamya', data: 'https://example.com/almamya', type: 'url' },
                                { key: '0-1', label: 'E.P la gare', data: 'https://example.com/lagare', type: 'url' },
                                { key: '0-2', label: 'E.P Franco Arabe gare', data: 'https://example.com/francoarabegare', type: 'url' },
                                { key: '0-3', label: 'G.S Jacqueline Bangoura', data: 'https://example.com/jacquelinebangoura', type: 'url' }
                            ]
                        },
                        {
                            key: '1',
                            label: 'Boulbinet',
                            children: [
                                { key: '1-0', label: 'G.S Aboud', data: 'https://angular.io/guide/lifecycle-hooks', type: 'url' },
                                { key: '1-1', label: 'École des sourds muets de Boulbinet', data: 'https://angular.io/guide/user-input', type: 'url' },
                                { key: '1-2', label: 'E.P Gendarmerie', data: 'https://angular.io/guide/component-interaction', type: 'url' }
                            ]
                        },
                         {
                             key: '2',
                             label: 'Coronthie',
                             children: [
                                 { key: '2-0', label: 'G.S Damba', data: 'https://angular.io/guide/component-interaction', type: 'url' },
                                 { key: '2-1', label: 'E.P A.S. Touré', data: 'https://angular.io/guide/user-input', type: 'url' }
                            ]
                         },
                         {
                              key: '3',
                              label: 'Fotoba',
                              children: [
                                 { key: '3-0', label: 'E.P Fotoba', data: 'https://angular.io/guide/component-interaction', type: 'url' },
                                 { key: '3-1', label: 'E.P C. islamique', data: 'https://angular.io/guide/user-input', type: 'url' }
                              ]
                         }
            ];

            this.sousPrefectures1 = [
                                    {
                                        key: '0',
                                        label: 'Cité Enco 5',
                                        children: [
                                            { key: '0-0', label: 'What is Angular', data: 'https://angular.io', type: 'url' },
                                            { key: '0-1', label: 'Getting Started', data: 'https://angular.io/guide/setup-local', type: 'url' },
                                            { key: '0-2', label: 'Learn and Explore', data: 'https://angular.io/guide/architecture', type: 'url' },
                                            { key: '0-3', label: 'Take a Look', data: 'https://angular.io/start', type: 'url' }
                                        ]
                                    },
                                    {
                                        key: '1',
                                        label: 'Cosa',
                                        children: [
                                            { key: '1-0', label: 'Component Registration', data: 'https://angular.io/guide/component-interaction', type: 'url' },
                                            { key: '1-1', label: 'User Input', data: 'https://angular.io/guide/user-input', type: 'url' },
                                            { key: '1-2', label: 'Hooks', data: 'https://angular.io/guide/lifecycle-hooks', type: 'url' },
                                            { key: '1-3', label: 'Attribute Directives', data: 'https://angular.io/guide/attribute-directives', type: 'url' }
                                        ]
                                    },
                                     {
                                         key: '2',
                                         label: 'Dar-es-salam',
                                         children: [
                                             { key: '2-0', label: 'Component Registration', data: 'https://angular.io/guide/component-interaction', type: 'url' },
                                             { key: '2-1', label: 'User Input', data: 'https://angular.io/guide/user-input', type: 'url' },
                                             { key: '2-2', label: 'Hooks', data: 'https://angular.io/guide/lifecycle-hooks', type: 'url' },
                                             { key: '2-3', label: 'Attribute Directives', data: 'https://angular.io/guide/attribute-directives', type: 'url' }
                                        ]
                                     },
                                     {
                                         key: '3',
                                         label: 'Hamdallaye 1',
                                         children: [
                                           { key: '3-0', label: 'Component Registration', data: 'https://angular.io/guide/component-interaction', type: 'url' },
                                           { key: '3-1', label: 'User Input', data: 'https://angular.io/guide/user-input', type: 'url' },
                                           { key: '3-2', label: 'Hooks', data: 'https://angular.io/guide/lifecycle-hooks', type: 'url' },
                                           { key: '3-3', label: 'Attribute Directives', data: 'https://angular.io/guide/attribute-directives', type: 'url' }
                                         ]
                                     }
                        ];

            this.sousPrefectures2 = [
                                    {
                                            key: '0',
                                            label: 'Sinta',
                                            children: [
                                                        { key: '0-0', label: 'What is Angular', data: 'https://angular.io', type: 'url' },
                                                        { key: '0-1', label: 'Getting Started', data: 'https://angular.io/guide/setup-local', type: 'url' },
                                                        { key: '0-2', label: 'Learn and Explore', data: 'https://angular.io/guide/architecture', type: 'url' },
                                                        { key: '0-3', label: 'Take a Look', data: 'https://angular.io/start', type: 'url' }
                                            ]
                                            },
                                            {
                                                    key: '1',
                                                    label: 'Sogolon',
                                                    children: [
                                                        { key: '1-0', label: 'Component Registration', data: 'https://angular.io/guide/component-interaction', type: 'url' },
                                                        { key: '1-1', label: 'User Input', data: 'https://angular.io/guide/user-input', type: 'url' },
                                                        { key: '1-2', label: 'Hooks', data: 'https://angular.io/guide/lifecycle-hooks', type: 'url' },
                                                        { key: '1-3', label: 'Attribute Directives', data: 'https://angular.io/guide/attribute-directives', type: 'url' }
                                                    ]
                                            },
                                                 {
                                                     key: '2',
                                                     label: 'Tarihoye',
                                                     children: [
                                                         { key: '2-0', label: 'Component Registration', data: 'https://angular.io/guide/component-interaction', type: 'url' },
                                                         { key: '2-1', label: 'User Input', data: 'https://angular.io/guide/user-input', type: 'url' },
                                                         { key: '2-2', label: 'Hooks', data: 'https://angular.io/guide/lifecycle-hooks', type: 'url' },
                                                         { key: '2-3', label: 'Attribute Directives', data: 'https://angular.io/guide/attribute-directives', type: 'url' }
                                                    ]
                                                 },
                                                 {
                                                     key: '3',
                                                     label: 'Télimélé-Centre',
                                                     children: [
                                                       { key: '3-0', label: 'Component Registration', data: 'https://angular.io/guide/component-interaction', type: 'url' },
                                                       { key: '3-1', label: 'User Input', data: 'https://angular.io/guide/user-input', type: 'url' },
                                                       { key: '3-2', label: 'Hooks', data: 'https://angular.io/guide/lifecycle-hooks', type: 'url' },
                                                       { key: '3-3', label: 'Attribute Directives', data: 'https://angular.io/guide/attribute-directives', type: 'url' }
                                                     ]
                                    }
             ];

    }




  loadPrimairesFromQuery() {
//     this.progressBarValue = true;
//     this.searchQueryComponent.updateParams();
//     return this.primaireService.getPrimairesFromQuery(this.searchQueryComponent.searchQuery).subscribe((data) => {
//       this.primairesFromQuery = data;
//       this.searchQueryComponent.updateSelectedLocaliteValue();
//       this.progressBarValue = false;
//     }, error => {
//       console.error('There was an error!', error);
//       console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
//     });
  }


}
