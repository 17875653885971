import {Component, OnInit, OnDestroy } from '@angular/core';
import {MenuItem} from 'primeng/api';
import {MenuModel} from '../../models/menu.model';
import {KeycloakService} from "keycloak-angular";
import {routes} from "../../app-routing.module";
import {KeycloakProfile} from "keycloak-js";
import {FlashInfoService} from "../../services/commun/flashinfo.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  items: MenuItem[];
  public intervalId: any;
  public messages: string[] = ["Je t'aime", "Tu me manques"];
  public currentIndex: number = 0;

  constructor(private ks: KeycloakService, private flashInfoService: FlashInfoService) {
  }

  ngOnInit(): void {
    // this.montrerMenuSelonRoles();
    this.items = MenuModel.createMenu();
    // for (let item of this.items) {
    //   console.log(" ********** " + item.label)
    // }
    console.log(" NOM ::::: > " + this.ks.isLoggedIn());
       this.intervalId = setInterval(() => {
            const message = this.messages[this.currentIndex];
            this.flashInfoService.showFlashMessage(message, 5000);
            this.currentIndex = (this.currentIndex + 1) % this.messages.length;
          }, 500); // Ajoute un message toutes les 10 secondes
  }

   ngOnDestroy(): void {
     console.log('HeaderComponent Destroyed');
     if (this.intervalId) {
       clearInterval(this.intervalId);
     }
   }

  montrerMenuSelonRoles() {
    let rolesMenus = [];
    for (let item of routes) {
      if (item.hasOwnProperty("data")) {
        console.log(" +++++++++++++++++ " + item.path);
        let rolesRequired: string[] = item.data.roles;
        console.log(" this.ks.isUserInRole(r) "+ rolesRequired[0]);
        console.log(" this.ks.getUserRoles() " + this.ks.getUserRoles().length);
        console.log(" this TOTOTOTOTO  "+ this.ks.isUserInRole(rolesRequired[0]));
        if (rolesRequired.some((r) => this.ks.isUserInRole(r))) {
          console.log(" TTTTTTTTT : " + item.path);
        }
      }
    }
    // if (!item.data.absentDuMenu) {
    //   if (!item.hasOwnProperty("data")) {
    //     rolesMenus.push(item);
    //   } else {
    //     let rolesRequired: string[] = item.data.roles;
    //     if (rolesRequired.some((r) => this.ks.isUserInRole(r))) {
    //       rolesMenus.push(item);
    //     }
    //   }
    // }

  }

}
