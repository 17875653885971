import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchQueryComponent} from "../commun/search-query/search-query.component";
import {Professionnel} from "../../models/professionnel/professionnel.model";
import {ProfessionnelService} from "../../services/professionnel/professionnel.service";

@Component({
  selector: 'app-professionnel',
  templateUrl: './professionnel.component.html',
  styleUrls: ['./professionnel.component.css']
})
export class ProfessionnelComponent implements OnInit {

  sortOrder: number;
  sortField: string;
  progressBarValue: boolean = true;
  professionnels: Professionnel[];
  professionnelsFromQuery: Professionnel[];
  @ViewChild
  (SearchQueryComponent, {static: true}) searchQueryComponent;

  constructor(protected professionnelService: ProfessionnelService) {

  }

  ngOnInit(): void {
    this.loadProfessionnels();
    this.searchQueryComponent.typeEtablissement = "professionnels";
  }

  loadProfessionnels() {
    return this.professionnelService.getProfessionnels().subscribe((data) => {
      this.professionnels = data;
      this.professionnelsFromQuery = data;
      this.searchQueryComponent.etablissements = data;
      this.searchQueryComponent.updateSelectedLocaliteValue();
      this.searchQueryComponent.nbreEvaluations = data.filter(etablissement => etablissement.evaluated === true).length;
      this.progressBarValue = false;
    });
  }

  loadProfessionnelsFromQuery() {
    this.progressBarValue = true;
    this.searchQueryComponent.updateParams();
    return this.professionnelService.getProfessionnelsFromQuery(this.searchQueryComponent.searchQuery).subscribe((data) => {
      this.professionnelsFromQuery = data;
      this.searchQueryComponent.updateSelectedLocaliteValue();
      this.progressBarValue = false;
    }, error => {
      console.error('There was an error!', error);
      console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }


}
