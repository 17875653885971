
export class Image {
  previewImageSrc: string;
  thumbnailImageSrc: string;
  alt:string;
  title:string;

  constructor(previewImageSrc?: string, thumbnailImageSrc?: string, alt?: string, title?: string) {
    this.alt = alt;
    this.title = title;
    this.previewImageSrc = previewImageSrc;
    this.thumbnailImageSrc = thumbnailImageSrc;
  }

}
