import {MenuItem} from 'primeng/api';
import {Directive} from '@angular/core';

@Directive()
export abstract class MenuModel {

  public static createMenu(): MenuItem[] {
    return [
      {
        label: 'Primaire',
        items: [
          {
            label: 'Annuaire',
            icon: 'pi pi-fw pi-calendar-times', routerLink: ['/primaire']
          },
          {
            separator: true
          }
//           ,
//           {
//             label: 'Resultats',
//             icon: 'pi pi-fw pi-plus',
//             items: [
//               {
//                 label: 'Année N',
//                 icon: 'pi pi-fw pi-bookmark'
//               },
//               {
//                 label: 'Année N-1',
//                 icon: 'pi pi-fw pi-bookmark'
//               }
//             ]
//           }
        ]
      },
      {
        label: 'Collège',
        items: [
          {
            label: 'Annuaire',
            icon: 'pi pi-fw pi-calendar-times', routerLink: ['/college']
          },
          {
            separator: true
          }
        ]
      },
      {
        label: 'Lycée',
        items: [
          {
            label: 'Annuaire',
            icon: 'pi pi-fw pi-calendar-times', routerLink: ['/lycee']
          },
          {
            separator: true
          }
//           ,
//           {
//             label: 'Recherche',
//             icon: 'pi pi-fw pi-users',
//             items: [
//               {
//                 label: 'Filter',
//                 icon: 'pi pi-fw pi-filter',
//                 items: [
//                   {
//                     label: 'Print',
//                     icon: 'pi pi-fw pi-print'
//                   }
//                 ]
//               },
//               {
//                 icon: 'pi pi-fw pi-bars',
//                 label: 'List'
//               }
//             ]
//           }
        ]
      },
      {
        label: 'Supérieur',
        items: [
          {
            label: 'Annuaire',
            icon: 'pi pi-fw pi-calendar-times', routerLink: ['/superieur']
          },
          {
            separator: true
          }
//           ,
//           {
//             label: 'Edit',
//             icon: 'pi pi-fw pi-pencil',
//             items: [
//               {
//                 label: 'Save',
//                 icon: 'pi pi-fw pi-calendar-plus'
//               },
//               {
//                 label: 'Delete',
//                 icon: 'pi pi-fw pi-calendar-minus'
//               },
//             ]
//           }
        ]
      },
      {
        label: 'Professionnel',
        items: [
          {
            label: 'Annuaire',
            icon: 'pi pi-fw pi-calendar-times', routerLink: ['/professionnel']
          },
          {
            separator: true
          },
          {
             label: 'Listes...',
             icon: 'pi pi-fw pi-bars', routerLink: ['/liste-etablissements']
          },
          {
            separator: true
          }
//           ,
//           {
//             label: 'Archieve',
//             icon: 'pi pi-fw pi-calendar-times',
//             items: [
//               {
//                 label: 'Remove',
//                 icon: 'pi pi-fw pi-calendar-minus'
//               }
//             ]
//           }
        ]
      },
      {
           label: 'Classement',
           items: [
           {
               label: 'Primaire',
               icon: 'pi pi-fw pi-calendar-times',
               routerLink: ['/cPrimaire']
           },
           {
              separator: true
           },
      		 {
              label: 'Collège',
              icon: 'pi pi-fw pi-calendar-times', routerLink: ['/cCollege']
           },
           {
             separator: true
           },
      		 {
              label: 'Lycée',
              icon: 'pi pi-fw pi-calendar-times', routerLink: ['/cLycee']
           },
      		 {
                  separator: true
           }
           ]
      }
	  /*,
      {
        label: 'Admin',
        items: [
          {
            label: 'Création de la région' ,
            icon: 'pi pi-fw pi-plus',
            items: [
              {
                label: 'Création',
                icon: 'pi pi-fw pi-plus', routerLink: ['/region']
              },
              {
                label: 'Modification',
                icon: 'pi pi-fw pi-pencil'
              },

            ]
          },
          {
            separator: true
          },
          {
            label: 'Création préfecture' ,
            icon: 'pi pi-fw pi-plus',
            items: [
              {
                label: 'Création',
                icon: 'pi pi-fw pi-plus', routerLink: ['/prefecture']
              },
              {
                label: 'Modification',
                icon: 'pi pi-fw pi-pencil'
              },

            ]
          },
          {
            separator: true
          },
          {
            label: 'Création sous-préfecture',
            icon: 'pi pi-fw pi-plus',
            items: [
              {
                label: 'Création',
                icon: 'pi pi-fw pi-plus', routerLink: ['/sous-prefecture']
              },
              {
                label: 'Modification',
                icon: 'pi pi-fw pi-pencil'
              }
            ]
          },
          {
            separator: true
          },
          {
            label: 'Création établissement',
            icon: 'pi pi-fw pi-plus',
            items: [
              {
                label: 'Création',
                icon: 'pi pi-fw pi-plus'
              },
              {
                label: 'Modification',
                icon: 'pi pi-fw pi-pencil'
              }
            ]
          }
        ]
      }*/
    ]
  }

  ngOnInit() {
  }
}

