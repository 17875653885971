import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {PrimaireService} from "../../../services/primaire/primaire.service";
import {SuperieurService} from "../../../services/superieur/superieur.service";
import {CollegeService} from "../../../services/college/college.service";
import {LyceeService} from "../../../services/lycee/lycee.service";
import {Location} from '@angular/common';
import {Galleria} from "primeng/galleria";
import {Image} from "../../../models/commun/image";
import {ProfessionnelService} from "../../../services/professionnel/professionnel.service";
import {PrefectureService} from "../../../services/prefecture/prefecture.service";
import {SousPrefectureService} from "../../../services/sous-prefecture/sous-prefecture-service";
import {Prefecture} from "../../../models/commun/prefecture";
import {SousPrefecture} from "../../../models/commun/sous-prefecture";
import { CheckboxModule } from 'primeng/checkbox';
import {SearchQueryComponent} from "../search-query/search-query.component";
import {CommunService} from "../../../services/commun/commun.service";
import {LabelService} from "../../../services/commun/label.service";
import {Lycee} from "../../../models/lycee/lycee";
import {College} from "../../../models/college/college";
import {Primaire} from "../../../models/primaire/primaire";
import {Superieur} from "../../../models/superieur/superieur.model";
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-detail-localite',
  templateUrl: './detail-localite.component.html',
  styleUrls: ['./detail-localite.component.css']
})
export class DetailLocaliteComponent implements OnInit {

  images: any[] = [];
  id: number;
  first: number = 0;
  etablissement: any;
  titrePrefecture: any;
  hasLangues = false;
  totalRecords: number = 3;
  nomGalerie: string = 'nomGalerie';
  previousUrl: string = "/localite";
  warningMsg: boolean = false;
  emptyEtablissement: boolean = false;
  siteWebDisabled: boolean = false;
  galeriePhotosExist: boolean = false;
  prefecture:any;
  statutOptions: any[];
  prives: boolean = true;
  publics: boolean = true;
  elementaires: boolean = false;
  colleges: boolean = false;
  lycees: boolean = false;
  superieurs: boolean = false;
  professionnels: boolean = false;
  progressBarValue: boolean = true;
  bodyVisible: boolean = true;
  primairesFromQuery : Primaire[];
  collegesFromQuery: College[];
  lyceesFromQuery: Lycee[];
  superieursFromQuery: Superieur[];
  searchQueryComponent: any;
  selectedSousPrefecture: any;
  sousPrefectures: SousPrefecture[];
  selectedSousPrefectures: any[];
  totalNbreEtablissements: number = 0;
  label: string = '';

  displayBasic2: boolean;
  displayBasic3: boolean;
  displayCustom: boolean;
  activeIndex: number = 0;

  constructor(private route: ActivatedRoute,
              private location: Location,
              private readonly router: Router,
              private activatedRoute: ActivatedRoute,
              protected primaireService: PrimaireService,
              protected collegeService: CollegeService,
              protected lyceeService: LyceeService,
              protected superieurService: SuperieurService,
              private commonService: CommunService,
              private prefectureService: PrefectureService,
              private sousPrefectureService: SousPrefectureService,
              private labelService: LabelService) {
    this.searchQueryComponent = new SearchQueryComponent(commonService);
  }

  ngOnInit(): void {
    this.label = this.labelService.getLabel();
    this.totalNbreEtablissements =Number(this.labelService.getLabel());
    console.log(' this.label '+this.label);
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.previousUrl = "/"+ params.myURL;
    });
    this.activatedRoute.params.subscribe(params => {
        console.log('Les paramètres de route ont changé :');
        console.log(' ces parametres '+JSON.stringify(params));
        this.label = this.labelService.getLabel();
        console.log('Le label  :'+this.label);
        this.totalNbreEtablissements =Number(this.labelService.getLabel());
        this.elementaires = false;
        this.colleges = false;
        this.lycees = false;
        this.superieurs = false;
        this.progressBarValue = false;
        this.emptyEtablissement = false;
        this.loadSousPrefectures();
      });
  }

    loadSousPrefectures() {
       return this.sousPrefectureService.sousPrefectures(this.id).subscribe((data) => {
                this.sousPrefectures = data;
                console.log(" pref-new " + JSON.stringify(this.sousPrefectures));
                this.findPrefecture();
              }, error => {
                console.error('There was an error!', error);
                console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++");
              });
    }

    findPrefecture() {
       return this.prefectureService.findPrefecture(this.id).subscribe((data) => {
                this.prefecture = data;
                console.log(" pref-new " + JSON.stringify(this.prefecture));
                this.titrePrefecture = this.prefecture.nom;
                window.scrollTo(0, 0);
              }, error => {
                console.error('There was an error!', error);
                console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++");
              });
    }

 loadPrimaires(searchQuery: any) {
    return this.primaireService.getPrimairesFromSousPrefectureName(this.searchQueryComponent.searchQuery).subscribe((data) => {
          this.primairesFromQuery = data;
           console.log(" this.primairesFromQuery " + JSON.stringify(this.primairesFromQuery));
          if (this.primairesFromQuery.length > 0) {
              this.elementaires = true;
              this.emptyEtablissement = false;
               if (this.collegesFromQuery.length > 0) {
                  this.colleges = true;
               }
               if (this.lyceesFromQuery.length > 0) {
                  this.lycees = true;
               }
               if (this.superieursFromQuery.length > 0) {
                  this.superieurs = true;
               }
          } else {
             this.emptyEtablissement = true;
          }
          this.totalNbreEtablissements=this.primairesFromQuery.length+this.collegesFromQuery.length+this.lyceesFromQuery.length+this.superieursFromQuery.length;
          this.progressBarValue = false;
          console.log(" this.all " + JSON.stringify(this.totalNbreEtablissements));
        }, error => {
          console.error('There was an error!', error);
          console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
        });
  }

  loadColleges(searchQuery: any) {
    return this.collegeService.getCollegesFromSousPrefectureName(this.searchQueryComponent.searchQuery).subscribe((data) => {
          this.collegesFromQuery = data;
          // APPEL PRIMAIRES
          this.loadPrimaires(this.searchQueryComponent.searchQuery);
        }, error => {
          console.error('There was an error!', error);
          console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
        });
  }

  loadLycees(searchQuery: any) {
      return this.lyceeService.getLyceesFromSousPrefectureName(this.searchQueryComponent.searchQuery).subscribe((data) => {
            this.lyceesFromQuery = data;
            //APPEL COLLEGES
            this.loadColleges(this.searchQueryComponent.searchQuery);
          }, error => {
            console.error('There was an error!', error);
            console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
          });
  }

   loadSuperieurs(searchQuery: any) {
        return this.superieurService.getSuperieursFromQuery(this.searchQueryComponent.searchQuery).subscribe((data) => {
              this.superieursFromQuery = data;
              //APPEL LYCEES
              this.loadLycees(this.searchQueryComponent.searchQuery);
            }, error => {
              console.error('There was an error!', error);
              console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
            });
   }

   filtrer() {
       this.progressBarValue = true;
       if (this.selectedSousPrefecture === 'undefined' || this.selectedSousPrefecture==null) {
         this.warningMsg = true;
         this.progressBarValue = false;
         this.emptyEtablissement = false;
       } else {
          this.warningMsg = false;
          this.searchQueryComponent.searchQuery.localite=this.selectedSousPrefecture.nom;
          this.searchQueryComponent.updateParams();
          //APPEL SuperieurService
          this.loadSuperieurs(this.searchQueryComponent.searchQuery);
       }
   }

  responsiveOptions:any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

}
